<template>
  <div class="number-input">
    <fieldset class="number-input__wrapper">
      <input
        class="number-input__input"
        :name="name"
        :id="name"
        type="number"
        min="0"
        :value="modelValue"
        :placeholder="placeholder"
        @input="updateValue"
      />
      <label class="number-input__label" :for="name">{{ label }}</label>
    </fieldset>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["update", "update:modelValue"]);

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>
<script>
// import { useField } from "vee-validate";
// import { toRef } from "vue";

export default {
  name: "AppNumberInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.number-input {
  position: relative;
  display: inline-block;
  margin-bottom: calc(1em * 1.5);
  width: auto;
  // max-width: 260px;

  &__wrapper {
    display: flex;
    align-items: end;
  }
  &__label {
    display: block;
    width: 100%;
    color: #888888;
    margin-left: 16px;
    font: 20px Montserrat;
  }
  &__sub-label {
    display: block;
    margin-bottom: 18px;
    width: 100%;
    color: #888888;
    font: italic normal medium 20px/30px Montserrat;
  }

  &__input {
    font: 20px Montserrat;
    color: var(--base-text-color);
    box-sizing: border-box;
    border: none;
    padding: 15px 20px;
    outline: none;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #707070;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
