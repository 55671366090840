<template>
  <DefaultLayout :fullWidth="true">
    <template v-if="activeTab <= countableSteps">
      <ScheduleHeader :activeTab="activeTab" />
      <div class="schedule-steps-subheading">
        <h2>
          {{ currentTabName.title }}
        </h2>
      </div>
    </template>
    <component :is="currentTabName.componentName"></component>
  </DefaultLayout>
</template>

<script>
import ScheduleHeader from "@/components/schedule/ScheduleHeader";
import DefaultLayout from "@/views/layouts/default";
import ScheduleStepsAccount from "@/components/schedule/schedule-steps/ScheduleStepsAccount";
import ScheduleStepsTiming from "@/components/schedule/schedule-steps/ScheduleStepsTiming";
import ScheduleStepsPersonalInfo from "@/components/schedule/schedule-steps/ScheduleStepsPersonalInfo";
import ScheduleStepsTerms from "@/components/schedule/schedule-steps/ScheduleStepsTerms";
import SchedulePhotoUpload from "@/components/schedule/schedule-steps/SchedulePhotoUpload";
import ScheduleStepsRentOrOwn from "@/components/schedule/schedule-steps/ScheduleStepsRentOrOwn";
import ScheduleStepsPropertyType from "@/components/schedule/schedule-steps/ScheduleStepsPropertyType";
import ScheduleStepsChargerLocation from "@/components/schedule/schedule-steps/ScheduleStepsChargerLocation";
import ScheduleStepsGarageSize from "@/components/schedule/schedule-steps/ScheduleStepsGarageSize";
import ScheduleStepsWallMount from "@/components/schedule/schedule-steps/ScheduleStepsWallMount";
import ScheduleStepsElectricalPanel from "@/components/schedule/schedule-steps/ScheduleStepsElectricalPanel";
import ScheduleStepsElectricalPanelLocation from "@/components/schedule/schedule-steps/ScheduleStepsElectricalPanelLocation";
import ScheduleStepsElectricalPanelBreaker from "@/components/schedule/schedule-steps/ScheduleStepsElectricalPanelBreaker";
import ScheduleStepsEVChargerDistance from "@/components/schedule/schedule-steps/ScheduleStepsEVChargerDistance";
import ScheduleStepsWiringAccess from "@/components/schedule/schedule-steps/ScheduleStepsWiringAccess";
import ScheduleStepsAgeOfHome from "@/components/schedule/schedule-steps/ScheduleStepsAgeOfHome";
import ScheduleStepsHomeSize from "@/components/schedule/schedule-steps/ScheduleStepsHomeSize";
import ScheduleStepsCheckList from "@/components/schedule/schedule-steps/ScheduleStepsCheckList";
import ScheduleStepsSolarPower from "@/components/schedule/schedule-steps/ScheduleStepsSolarPower";
import ScheduleStepsScheduleInstallation from "@/components/schedule/schedule-steps/ScheduleStepsScheduleInstallation";
import ScheduleStepsCongrats from "@/components/schedule/schedule-steps/ScheduleStepsCongrats";

import { computed } from "vue";
import { requestFormSteps } from "@/common/enums";
import { useScheduleStore } from "@/store/ScheduleStepsData";
import ScheduleStepsWelcome from "@/components/schedule/schedule-steps/ScheduleStepsWelcome.vue";

const store = useScheduleStore();

export default {
  name: "Schedule",
  components: {
    ScheduleStepsTiming,
    ScheduleStepsAccount,
    DefaultLayout,
    ScheduleHeader,
    ScheduleStepsTerms,
    SchedulePhotoUpload,
    ScheduleStepsPersonalInfo,
    ScheduleStepsRentOrOwn,
    ScheduleStepsPropertyType,
    ScheduleStepsChargerLocation,
    ScheduleStepsGarageSize,
    ScheduleStepsWallMount,
    ScheduleStepsElectricalPanel,
    ScheduleStepsElectricalPanelLocation,
    ScheduleStepsElectricalPanelBreaker,
    ScheduleStepsEVChargerDistance,
    ScheduleStepsWiringAccess,
    ScheduleStepsAgeOfHome,
    ScheduleStepsHomeSize,
    ScheduleStepsCheckList,
    ScheduleStepsSolarPower,
    ScheduleStepsScheduleInstallation,
    ScheduleStepsCongrats,
    ScheduleStepsWelcome,
  },
  setup() {
    const countableSteps = Object.keys(requestFormSteps).length - 1;
    const activeTab = computed(() => {
      return store.scheduleStep;
    });
    const currentTabName = computed(() => {
      const data = {
        componentName: "",
        ..._.find(requestFormSteps, (step) => step.key === activeTab.value),
      };
      data.componentName = _.find(
        Object.keys(requestFormSteps),
        (step) => requestFormSteps[step].key === activeTab.value
      );
      if (data.title) {
        data.title = data.title
          .split(" ")
          .map((word) => word[0].toUpperCase() + word.substr(1))
          .join(" ");
      }
      return data;
    });
    const currentStepNumber = computed(() => {
      const selectedChargerLocation = store.scheduleData.charger_location;
      if (
        (selectedChargerLocation === "mounted_to_fence" ||
          selectedChargerLocation === "outside_home_wall") &&
        currentTabName.value.key > 7
      ) {
        return currentTabName.value.key - 1;
      }
      if (
        selectedChargerLocation === "mounted_to_pedestal" &&
        currentTabName.value.key > 7
      ) {
        return currentTabName.value.key - 2;
      }
      return currentTabName.value.key;
    });

    return {
      activeTab,
      currentTabName,
      currentStepNumber,
      countableSteps,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 100px 140px;
}
.schedule-steps-subheading {
  margin-top: 60px;
  margin-bottom: 48px;
  font: normal normal bold 27px/68px Montserrat;
  letter-spacing: 0;
  color: var(--base-text-color);
  @media screen and (max-width: 550px) {
    font: normal normal bold 28px/48px Montserrat;
  }
}
</style>
