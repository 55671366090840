<template>
  <div class="wiring">
    <h2 class="wiring__subtitle">
      Where can we route the wiring from the electrical panel to the charger?
    </h2>
    <AppRadioGroupWithImage
      class="wiring__ body"
      id="wiring-access"
      :options="options"
      v-model="selected"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false && selected === '' }"
      >
        This field is required
      </p>
    </div>
    <h2 class="wiring__subtitle">Do you have plaster interior walls?</h2>
    <AppRadioGroup
      class="wiring__ body"
      id="plaster-walls"
      :options="optionsPlasterWalls"
      v-model="selectedPlasterWalls"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{
          helpMessage__active:
            validated === false && selectedPlasterWalls === '',
        }"
      >
        This field is required
      </p>
    </div>
    <ScheduleStepsAction
      class="wiring__actions"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import AppRadioGroupWithImage from "@/components/common/AppRadioGroupWithImage";
import AppRadioGroup from "@/components/common/AppRadioGroup";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";

import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsWiringAccess",
  components: { ScheduleStepsAction, AppRadioGroupWithImage, AppRadioGroup },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected = localStepData?.wiring_access
      ? ref(localStepData?.wiring_access)
      : ref("");
    const selectedPlasterWalls = localStepData?.plaster_walls
      ? ref(localStepData?.plaster_walls)
      : ref("");
    const options = [
      {
        value: "outside_home_wall",
        text: "Along exterior wall",
        image: "/images/wiring-access/Outside.jpg",
      },
      {
        value: "attic",
        text: "Attic",
        image: "/images/wiring-access/Attic.jpg",
      },
      {
        value: "below_home",
        text: "Crawl space below the home",
        image: "/images/wiring-access/Crawl.jpg",
      },
      {
        value: "bare_basement",
        text: "Unfinished basement",
        image: "/images/wiring-access/Unfinished.jpg",
      },
      {
        value: "finished_basement",
        text: "Finished Basement",
        image: "/images/wiring-access/Finished.jpg",
      },
      {
        value: "other",
        text: "Other/Don’t know",
        image: "/images/Other.jpg",
      },
    ];
    const optionsPlasterWalls = [
      {
        value: "true",
        text: "Yes",
      },
      {
        value: "false",
        text: "No",
      },
      {
        value: "don't know",
        text: "Don't know",
      },
    ];

    const validateData = () =>
      selected.value !== "" && selectedPlasterWalls.value !== "";

    let validated = validateData() ? ref(true) : ref(null);

    watch([selected, selectedPlasterWalls], () => {
      if (validated.value === false) {
        validated.value = validateData();
      } else {
        validated.value = validateData() === false ? null : true;
      }
    });

    function updateData() {
      if (!validateData()) {
        validated.value = false;
      }
      stepsStore.setScheduleStepData({
        wiring_access: selected.value,
        plaster_walls: selectedPlasterWalls.value,
      });
    }

    return {
      selected,
      selectedPlasterWalls,
      options,
      optionsPlasterWalls,
      updateData,
      validated,
    };
  },
};
</script>

<style lang="scss" scoped>
.wiring {
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
