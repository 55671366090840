<template>
  <div class="panel">
    <h2 class="panel__subtitle">
      Is your main electrical panel 200 Amp or 100 Amp service?
    </h2>
    <div class="panel__body">
      <AppRadioGroup id="panel" :options="options" v-model="selected" />
      <img class="panel__image" src="images/ElPanel.png" alt="" />
    </div>
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        This field is required
      </p>
    </div>
    <ScheduleStepsAction
      @prev="whichStepIsBefore"
      @next="updateData"
      :validated="validated"
    />
    <AdditionalCostsModal
      v-if="showModal"
      @close="showModal = false"
      @agreed="goToNextStep"
      customText="If service level is 100A"
    />
  </div>
</template>

<script>
import AppRadioGroup from "@/components/common/AppRadioGroup";
import { ref, reactive, watch, computed } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import AdditionalCostsModal from "@/components/layouts/AdditionalCostsModal";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsElectricalPanel",
  components: { ScheduleStepsAction, AppRadioGroup, AdditionalCostsModal },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected =
      localStepData?.electrical_panel_service !== undefined
        ? localStepData.electrical_panel_service === null
          ? ref("don't know")
          : ref(localStepData?.electrical_panel_service)
        : ref("");
    // const selected = localStepData?.electrical_panel_service
    //   ? ref(localStepData?.electrical_panel_service)
    //   : ref("");
    let showModal = ref(false);
    const options = [
      {
        value: "200A",
        text: "200A",
      },
      {
        value: "100A",
        text: "100A",
      },
      {
        value: "don't know",
        text: "Don't know",
      },
    ];
    const selectedChargerLocation = computed(() => {
      if (localStepData?.charger_location) {
        return localStepData.charger_location;
      }
      return null;
    });
    const whichStepIsBefore = () => {
      if (selectedChargerLocation.value === "mounted_to_pedestal") {
        stepsStore.prevStep();
        stepsStore.prevStep();
      }
    };

    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, () => {
      if (selected.value === "") {
        validated.value = false;
      } else if (selected.value === "100A") {
        validated.value = null;
      } else {
        validated.value = true;
      }
    });

    const goToNextStep = () => {
      validated.value = true;
      showModal.value = false;
      stepsStore.nextStep();
    };

    function updateData() {
      if (selected.value === "") {
        validated.value = false;
      }
      if (selected.value === "100A") {
        showModal.value = true;
      }
      stepsStore.setScheduleStepData({
        electrical_panel_service:
          selected.value === "don't know" ? null : selected.value,
        // electrical_panel_service: selected.value,
      });
    }
    return {
      selected,
      options,
      updateData,
      validated,
      showModal,
      goToNextStep,
      whichStepIsBefore,
    };
  },
};
</script>

<style lang="scss" scoped>
.panel {
  width: 80%;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
    display: flex;
    margin-bottom: 70px;
    justify-content: space-between;
    #panel {
      flex-shrink: 0;
      margin-right: 15px;
      @media screen and (max-width: 1240px) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 1240px) {
      flex-direction: column;
    }
  }
  &__image {
    width: 85%;
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
