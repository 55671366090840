<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <h2>Property Owner/Property Management contact information</h2>
        </header>

        <section class="modal-body" id="modalDescription">
          <p>
            You have indicated that this is a Rental Property. Please enter
            contact information for the Property Owner/Property Management
            Group, who can authorize work to be done on the property by signing
            a letter we will send to them. Installation will not proceed until
            we receive this signed letter back.
          </p>
          <Form
            class="personal-info__form"
            @submit="onSubmit"
            :validation-schema="propertyOwnerInformationSchema"
            @invalid-submit="onInvalidSubmit"
          >
            <AppInput
              name="owner_name"
              placeholder="Name "
              label="Name"
              :value="localStepData.owner_name"
            />
            <AppInput
              name="company_name"
              label="Company name (optional)"
              placeholder="Company name"
              :value="localStepData.company_name"
            />
            <AppInput
              name="owner_email"
              label="Email"
              type="email"
              placeholder="Email"
              :value="localStepData.owner_email"
            />
            <AppInput
              name="owner_phone"
              placeholder="xxx-xxx-xxxx"
              label="Phone"
              class="personal-info__phone"
              :value="localStepData.owner_phone"
            />
            <div class="modal__actions">
              <!-- <AppButton @click="prevAction"> Previous </AppButton> -->
              <AppButton @click="close" type="button">
                I’M NOT A RENTER
              </AppButton>
              <AppButton class="app-btn" type="submit"> SUBMIT </AppButton>
            </div>
            <!-- <AppButton class="app-btn" type="submit"> SEND </AppButton> -->
          </Form>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import AppInput from "@/components/common/AppInput";
import AppButton from "@/components/common/AppButton";
import { useScheduleStore } from "@/store/ScheduleStepsData";
// import { useAuthStore } from "@/store/AuthStore";
import { reactive } from "vue";

// const authStore = useAuthStore();
const stepsStore = useScheduleStore();

export default {
  name: "RentalPropertyModal",
  components: { AppInput, Form, AppButton },
  props: {
    customText: {
      type: String,
      required: false,
      default: "",
    },
    rentType: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const localStepData = reactive(stepsStore.scheduleData);
    const propertyOwnerInformationSchema = Yup.object().shape({
      owner_name: Yup.string().required("This field is required"),
      company_name: Yup.string(),
      owner_email: Yup.string()
        .email("The email must be a valid email address")
        .required("This field is required"),
      owner_phone: Yup.string()
        .required("This field is required")
        .matches(/^\+?(?:[0-9]?){6,14}[0-9]$/, "Invalid phone number")
        .transform((value) => value.replace("+", "")),
    });
    function onSubmit(values) {
      // console.log("values: ", values);
      stepsStore.setScheduleStepData({
        property_status: props.rentType,
        owner_name: values.owner_name,
        company_name: values.company_name,
        owner_email: values.owner_email,
        owner_phone: values.owner_phone,
      });
      // stepsStore.setScheduleStepData({
      //   ...values,
      // });
      stepsStore.nextStep();
    }
    function onInvalidSubmit() {
      // console.log("something went wrong");
      const submitBtn = document.querySelector(".app-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
      // let domRect = document
      //   .querySelector(".has-error")
      //   .getBoundingClientRect();
      // window.scrollTo(
      //   domRect.left + document.documentElement.scrollLeft,
      //   domRect.top + document.documentElement.scrollTop
      // );
    }
    return {
      onSubmit,
      propertyOwnerInformationSchema,
      onInvalidSubmit,
      localStepData,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  opacity: 1;
  flex-direction: column;
  max-width: 800px;
  max-height: 90vh;
  border-radius: 15px;
  @media screen and (max-width: 500px) {
    max-width: 95%;
  }
}
.modal-header {
  background: var(--buzz-charging-dark-blue);
  color: #fff;
  font: normal normal bold 40px Futura;
  text-align: center;
  padding: 25px 55px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
}

.modal-body {
  padding: 30px 50px;
  min-width: 100%;
  font: 25px/40px Montserrat;
  color: var(--base-text-color);
  text-align: center;
  line-height: 34px;
  max-width: 450px;
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
}

.modal__actions {
  display: flex;
  justify-content: space-between;
  button {
    width: 45%;
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
a {
  color: var(--buzz-charging-dark-blue);
  text-decoration: none;
}

.modalApprove {
  display: block;
  margin: 1.5rem auto;
  border-radius: 5px;
  padding: 1rem 7rem;
  font: normal normal 600 25px/30px Montserrat;
  color: #fff;
  background-color: var(--base-text-color);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: var(--buzz-charging-yellow);
    color: var(--buzz-charging-dark-blue);
  }
  @media (max-width: 550px) {
    padding: 1rem;
  }
}
</style>
