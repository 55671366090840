<template>
  <div class="garage">
    <h2 class="garage__subtitle">What size is your garage?</h2>
    <AppRadioGroup
      class="garage__body"
      id="garage"
      :options="options"
      v-model="selected"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false && selected === '' }"
      >
        This field is required
      </p>
    </div>
    <h2 class="garage__subtitle">Is your garage detached?</h2>
    <AppRadioGroup
      class="garage__body"
      id="garageDetached"
      :options="optionsForDetached"
      v-model="selectedDetached"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{
          helpMessage__active: validated === false && selectedDetached === '',
        }"
      >
        This field is required
      </p>
    </div>
    <div class="detachedOptions" v-if="selectedDetached === 'true'">
      <h2 class="garage__subtitle">
        Is there a sub panel in the detached garage?
      </h2>
      <AppRadioGroup
        class="garage__body"
        id="garageSubpanel"
        :options="optionsForSubpanel"
        v-model="selectedSubpanel"
      />
      <div class="helpMessageBlock">
        <p
          class="helpMessage"
          :class="{
            helpMessage__active:
              validated === false &&
              selectedDetached === 'true' &&
              selectedSubpanel === '',
          }"
        >
          This field is required
        </p>
      </div>
      <h2 class="garage__subtitle">
        Is there conduit running between your house and the detached garage?
      </h2>
      <AppRadioGroup
        class="garage__body"
        id="garageConduit"
        :options="optionsForConduit"
        v-model="selectedConduit"
      />
      <div class="helpMessageBlock">
        <p
          class="helpMessage"
          :class="{
            helpMessage__active:
              validated === false &&
              selectedDetached === 'true' &&
              selectedConduit === '',
          }"
        >
          This field is required
        </p>
      </div>
    </div>
    <ScheduleStepsAction
      class="garage__actions"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import AppRadioGroup from "@/components/common/AppRadioGroup";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsGarageSize",
  components: { ScheduleStepsAction, AppRadioGroup },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected = localStepData?.garage_size
      ? ref(localStepData?.garage_size)
      : ref("");
    const selectedDetached = localStepData?.garage_detached
      ? ref(localStepData?.garage_detached)
      : ref("");
    const selectedSubpanel = localStepData?.garage_subpanel
      ? ref(localStepData?.garage_subpanel)
      : ref("");
    const selectedConduit = localStepData?.garage_conduit
      ? ref(localStepData?.garage_conduit)
      : ref("");
    const options = [
      {
        value: "1",
        text: "1 Car",
      },
      {
        value: "2",
        text: "2 Car",
      },
      {
        value: "3",
        text: "3 Car",
      },
      {
        value: "4",
        text: "4 Car",
      },
    ];
    const optionsForDetached = [
      {
        value: "true",
        text: "Yes",
      },
      {
        value: "false",
        text: "No",
      },
    ];
    const optionsForSubpanel = [
      {
        value: "true",
        text: "Yes",
      },
      {
        value: "false",
        text: "No",
      },
      {
        value: "don't know",
        text: "Don't know",
      },
    ];
    const optionsForConduit = [
      {
        value: "true",
        text: "Yes",
      },
      {
        value: "false",
        text: "No",
      },
      {
        value: "don't know",
        text: "Don't know",
      },
    ];
    const validateData = () => {
      let result = true;
      if (selected.value === "") {
        result = false;
      } else if (selectedDetached.value === "") {
        result = false;
      } else if (
        selectedDetached.value === "true" &&
        (selectedSubpanel.value === "" || selectedConduit.value === "")
      ) {
        result = false;
      }
      return result;
    };
    const validated = validateData() === false ? ref(null) : ref(true);

    watch(
      [selected, selectedDetached, selectedSubpanel, selectedConduit],
      () => {
        if (validated.value === false) {
          validated.value = validateData();
        } else {
          validated.value = validateData() === false ? null : true;
        }
      }
    );

    function updateData() {
      if (
        selected.value === "" ||
        selectedDetached.value === "" ||
        (selectedDetached.value === "true" &&
          (selectedSubpanel.value === "" || selectedConduit.value === ""))
      ) {
        validated.value = false;
      }
      stepsStore.setScheduleStepData({
        garage_size: selected.value,
        garage_detached: selectedDetached.value,
      });
      if (selectedSubpanel.value !== "") {
        stepsStore.setScheduleStepData({
          garage_subpanel: selectedSubpanel.value,
        });
      }
      if (selectedConduit.value !== "") {
        stepsStore.setScheduleStepData({
          garage_conduit: selectedConduit.value,
        });
      }
    }

    return {
      selected,
      selectedDetached,
      selectedConduit,
      selectedSubpanel,
      options,
      updateData,
      validated,
      optionsForDetached,
      optionsForConduit,
      optionsForSubpanel,
    };
  },
};
</script>

<style lang="scss" scoped>
.garage {
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
