<template>
  <div class="welcome">
    <div class="welcome__mainText">
      <p>
        You will be required to submit the following photos. Please take them
        now for your convenience.
      </p>

      <ul class="welcome__list welcomeList">
        <li
          v-for="itemList in listPhotos"
          :key="itemList"
          class="welcomeList__checkboxBlock"
        >
          <div class="welcomeList__checkboxLine">
            <input
              class="welcomeList__checkboxItem"
              name="panelInside"
              type="checkbox"
              :value="true"
              checked
              id="panelInside"
              tabindex="-1"
            />
            <label for="panelInside" class="welcomeList__checkboxTitle">
              {{ itemList }}
            </label>
          </div>
        </li>
      </ul>
      <ul
        class="welcome__list welcomeList welcomeList--second"
        v-if="listAdditionalPhotos"
      >
        <li
          v-for="itemList in listAdditionalPhotos"
          :key="itemList"
          class="welcomeList__checkboxBlock"
        >
          <div class="welcomeList__checkboxLine">
            <input
              class="welcomeList__checkboxItem"
              name="panelInside"
              type="checkbox"
              :value="true"
              checked
              id="panelInside"
              tabindex="-1"
            />
            <label for="panelInside" class="welcomeList__checkboxTitle">
              {{ itemList }}
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <ScheduleStepsAction :withPrev="false" @next="updateScheduleData" />
</template>

<script>
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction.vue";

export default {
  name: "ScheduleStepsWelcome",
  components: { ScheduleStepsAction },
  setup() {
    const listPhotos = [
      "A photo of your main electrical panel standing 10 ft away.",
      "A photo of the inside of your electrical panel from close up.",
      "A photo of the area you wish to charge your vehicle from 10 ft away.",
    ];
    const listAdditionalPhotos = [
      "If you have a sub-panel, a photo from 10 ft away.",
      "If you have a sub-panel, a photo of the inside of the panel from close up.",
    ];

    return {
      listPhotos,
      listAdditionalPhotos,
    };
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
input[type="checkbox"][disabled] {
  border: 1px solid red; // or whatever
  background-color: blue !important;
}
.welcomePageHeader {
  img {
    display: block;
    width: 100%;
  }
}
.italic-font {
  font-style: italic;
}
.has-error {
  display: block;
  background-color: #fff;
  color: var(--error-color);
}
.submitBtn {
  max-width: 370px;
  @media screen and (max-width: 400px) {
    padding: 12px 24px;
  }
}
input[type="checkbox"] {
  width: 25px;
  height: 25px;
  margin-right: 20px;
  border: 1px solid var(--base-text-color);
  accent-color: var(--buzz-charging-dark-blue);
}
.welcome {
  display: flex;
  justify-content: space-between;
  padding: 0 140px 60px;
  @media screen and (max-width: 1440px) {
    padding: 60px 110px;
  }
  @media screen and (max-width: 1160px) {
    padding: 60px 80px;
  }
  @media screen and (max-width: 1024px) {
    display: block;
    padding: 60px;
  }
  @media screen and (max-width: 550px) {
    padding: 24px 30px;
  }
  @media screen and (max-width: 375px) {
    padding: 24px 20px;
  }
  &__main {
    width: 51%;
    @media (max-width: 1024px) {
      width: initial;
    }
  }
  &__title {
    font: normal normal bold 45px Futura;
    color: #888888;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 32px;
    }
  }
  &__mainText {
    font: normal normal normal 20px/32px Montserrat;
    color: #888888;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 1.5;
    }
    p {
      margin-bottom: 1.6rem;
    }
    .welcomeList {
      pointer-events: none;
      &--second {
        padding-top: 2rem;
        padding-left: 2rem;
      }
    }
    .welcomeList__title {
      margin-bottom: 27px;
    }
    .welcomeList__checkboxBlock {
      margin-left: 50px;
      margin-bottom: 27px;
      @media screen and (max-width: 1024px) {
        margin-left: 25px;
        margin-bottom: 15px;
      }
    }
    .welcomeList__checkboxLine {
      display: flex;
      align-items: center;
    }
  }
  form {
    width: 150%;
    @media (max-width: 1500px) {
      width: 190%;
    }
    @media screen and (max-width: 1024px) {
      width: initial;
    }
  }
  &__sidebar {
    padding-top: 30px;
    width: 42%;
    @media screen and (max-width: 1024px) {
      width: initial;
    }
  }
  &__img {
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
