<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <h2>Please Note Additional Costs May Apply</h2>
        </header>

        <section class="modal-body" id="modalDescription">
          {{ customText }} additional costs may apply.
          <button class="modalApprove" @click="agreed">I Understand</button>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AdditionalCostsModal",
  props: {
    customText: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    agreed() {
      this.$emit("agreed");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  opacity: 1;
  flex-direction: column;
  max-width: 710px;
  border-radius: 15px;
  @media screen and (max-width: 500px) {
    max-width: 95%;
  }
}
.modal-header {
  background: var(--buzz-charging-dark-blue);
  color: #fff;
  font: normal normal bold 40px Futura;
  text-align: center;
  padding: 32px 55px;
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
}

.modal-body {
  padding: 66px 86px;
  min-width: 100%;
  font: 25px/40px Montserrat;
  color: var(--base-text-color);
  text-align: center;
  line-height: 34px;
  max-width: 450px;
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
a {
  color: var(--buzz-charging-dark-blue);
  text-decoration: none;
}

.modalApprove {
  display: block;
  margin: 1.5rem auto;
  border-radius: 5px;
  padding: 1rem 7rem;
  font: normal normal 600 25px/30px Montserrat;
  color: #fff;
  background-color: var(--base-text-color);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: var(--buzz-charging-yellow);
    color: var(--buzz-charging-dark-blue);
  }
  @media (max-width: 550px) {
    padding: 1rem;
  }
}
</style>
