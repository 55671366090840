<template>
  <div class="age">
    <div class="home-info">
      <div>
        <h2 class="age__subtitle">When was your home built?</h2>
        <AppRadioGroup
          id="age"
          :options="options"
          v-model="selected"
          class="age__body"
        />
      </div>

      <ScheduleStepsHomeSize />
    </div>

    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        This field is required
      </p>
    </div>

    <ScheduleStepsAction
      class="age__actions"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import { ref, reactive, watch, computed } from "vue";
import AppRadioGroup from "@/components/common/AppRadioGroup";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";

import { useScheduleStore } from "@/store/ScheduleStepsData";
import ScheduleStepsHomeSize from "@/components/schedule/schedule-steps/ScheduleStepsHomeSize.vue";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsAgeOfHome",
  components: { ScheduleStepsHomeSize, ScheduleStepsAction, AppRadioGroup },

  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected = localStepData?.home_age
      ? ref(localStepData?.home_age)
      : ref("");
    const options = [
      {
        value: "2000AndMore",
        text: "2000 to present",
      },
      {
        value: "1980-1999",
        text: "1980 to 1999",
      },
      {
        value: "1960-1979",
        text: "1960 to 1979",
      },
      {
        value: "1940-1959",
        text: "1940 to 1959",
      },
      {
        value: "1940AndLess",
        text: "Prior to 1940",
      },
      {
        value: "unknown",
        text: "Don't know",
      },
    ];
    const minAge = computed(() => {
      if (selected.value.includes("AndLess")) {
        return null;
      }
      if (selected.value.includes("AndMore")) {
        return selected.value.split("AndMore")[0];
      }
      if (selected.value.split("-").length > 1) {
        return selected.value.split("-")[0];
      }
      return null;
    });
    const maxAge = computed(() => {
      if (selected.value.includes("AndMore")) {
        return null;
      }
      if (selected.value.includes("AndLess")) {
        return selected.value.split("AndLess")[0];
      }
      if (selected.value.split("-").length > 1) {
        return selected.value.split("-")[1];
      }
      return null;
    });

    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, () => {
      validated.value = selected.value !== "";
    });

    function updateData() {
      if (selected.value === "") {
        validated.value = false;
      }
      stepsStore.setScheduleStepData({
        home_age: selected.value,
        home_age_min: minAge.value,
        home_age_max: maxAge.value,
      });
    }

    return {
      selected,
      options,
      updateData,
      validated,
      localStepData,
    };
  },
};
</script>

<style lang="scss" scoped>
.home-info {
  display: flex;
}

.age {
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
    margin-bottom: 10px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
