<template>
  <div class="charger">
    <h2 class="charger__subtitle">
      How far is the EV Charger expected to be from the Main Electrical Panel?
      (est.)
    </h2>
    <div class="charger__body">
      <AppRadioSlider
        :options="options"
        v-model="selected"
        id="slider"
        class="charger__slider"
      />
      <AppRadioGroup
        :options="dontKnowOption"
        v-model="dontKnow"
        id="dontKnow"
      />
      <div class="measureInfo">
        <div class="measureInfo__imgBlock">
          <img src="/images/tape.png" alt="Tape measure" />
        </div>
        <p class="measureInfo__text">
          To calculate the distance between the Electrical Panel and the Charger
          location: Please measure down from your electrical panel box to the
          floor under it, then along the lower walls to get to your charger
          location, being sure to count distance around corners, then to the
          charger location about waist high for the charger height. This is not
          required but will make for a more accurate quote. Incorrect distance
          measurement may result in an additional charge.
        </p>
      </div>
      <div class="helpMessageBlock">
        <p
          class="helpMessage"
          :class="{ helpMessage__active: validated === false }"
        >
          This field is required
        </p>
      </div>
    </div>
    <ScheduleStepsAction
      class="charger__actions"
      @next="updateData"
      :validated="validated"
    />
    <AdditionalCostsModal
      v-if="showModal"
      @close="showModal = false"
      @agreed="goToNextStep"
      customText="Over 50 feet distance from Panel"
    />
  </div>
</template>

<script>
import { ref, watch, reactive, computed } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import AppRadioSlider from "@/components/common/AppRangeSlider";
import AppRadioGroup from "@/components/common/AppRadioGroup";
import AdditionalCostsModal from "@/components/layouts/AdditionalCostsModal";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();

export default {
  name: "ScheduleStepsEVChargerDistance",
  components: {
    AppRadioGroup,
    AppRadioSlider,
    ScheduleStepsAction,
    AdditionalCostsModal,
  },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected = localStepData?.charger_distance_location
      ? ref(localStepData?.charger_distance_location)
      : ref("");
    const options = [
      {
        value: "0-10",
        text: "0-10 ft",
      },
      {
        value: "11-20",
        text: "11-20 ft",
      },
      {
        value: "21-30",
        text: "21-30 ft",
      },
      {
        value: "31-40",
        text: "31-40 ft",
      },
      {
        value: "41-50",
        text: "41-50 ft",
      },
      {
        value: "51-60",
        text: "51-60 ft",
      },
      {
        value: "61-70",
        text: "61-70 ft",
      },
      {
        value: "71-80",
        text: "71-80 ft",
      },
      {
        value: "81-90",
        text: "81-90 ft",
      },
      {
        value: "91-100",
        text: "91-100 ft",
      },
      {
        value: "101+",
        text: "101+ ft",
      },
    ];
    let showModal = ref(false);
    const goToNextStep = () => {
      validated.value = true;
      showModal.value = false;
      stepsStore.nextStep();
    };

    const distanceMin = computed(() => {
      if (selected.value !== "") {
        if (selected.value.slice(-1) === "+") {
          return selected.value.slice(0, -1);
        }
        return selected.value.split("-")[0];
      }
      return null;
    });
    const distanceMax = computed(() => {
      if (selected.value !== "") {
        if (selected.value.slice(-1) === "+") {
          return null;
        }
        return selected.value.split("-")[1];
      }
      return null;
    });
    const dontKnow =
      localStepData?.charger_distance_location !== undefined
        ? localStepData.charger_distance_location === null
          ? ref("unknown")
          : ref("")
        : ref("");
    const dontKnowOption = [
      {
        value: "unknown",
        text: "Don't know",
      },
    ];

    watch(selected, (val) => {
      if (dontKnow.value !== "" && val !== "") {
        dontKnow.value = "";
      }
      if (dontKnow.value !== "" || val !== "") {
        validated.value = true;
      } else {
        validated.value = false;
      }
      if (selected.value === "" && dontKnow.value === "") {
        validated.value = false;
      } else if (distanceMin.value >= 50) {
        validated.value = null;
      } else {
        validated.value = true;
      }
    });
    watch(dontKnow, (val) => {
      if (val === "unknown") {
        validated.value = null;
        selected.value = "";
      }
    });
    const validated =
      selected.value !== "" || dontKnow.value !== "" ? ref(true) : ref(null);

    function updateData() {
      if (selected.value === "" && dontKnow.value === "") {
        validated.value = false;
      }

      if (distanceMin.value >= 50 || dontKnow.value === "unknown") {
        showModal.value = true;
        validated.value = null;
      }
      stepsStore.setScheduleStepData({
        charger_distance_location:
          dontKnow.value === "" ? selected.value : null,
        charger_distance_min: distanceMin.value,
        charger_distance_max: distanceMax.value,
      });
    }

    return {
      selected,
      options,
      dontKnow,
      dontKnowOption,
      showModal,
      goToNextStep,
      validated,
      updateData,
    };
  },
};
</script>

<style lang="scss" scoped>
.charger {
  .measureInfo {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }
    &__imgBlock {
      min-width: 10%;
      padding-right: 1%;
      img {
        // max-width: 50px;
        width: 100%;
        display: block;
      }
    }
    &__text {
      color: var(--base-text-color);
      font: italic normal normal 16px/20px Montserrat;
    }
  }
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
  }
  &__slider {
    margin-bottom: 100px;
    @media screen and (max-width: 670px) {
      margin-bottom: 40px;
    }
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
