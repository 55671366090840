<template>
  <div class="wall">
    <h2 class="wall__subtitle">
      {{ componentInfo[selectedChargerLocation]?.subtitle }}
    </h2>
    <AppRadioGroupWithImage
      id="wall"
      v-model="selected"
      :options="options[selectedChargerLocation]"
      fullWidth
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        This field is required
      </p>
    </div>
    <ScheduleStepsAction
      class="wall__actions"
      @prev="whichStepIsBefore"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import AppRadioGroupWithImage from "@/components/common/AppRadioGroupWithImage";
import { ref, reactive, watch, computed } from "vue";
import { useScheduleStore } from "@/store/ScheduleStepsData";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsWallMount",
  components: { ScheduleStepsAction, AppRadioGroupWithImage },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected = localStepData?.outside_charger_location
      ? ref(localStepData?.outside_charger_location)
      : localStepData?.fence_charger_location
      ? ref(localStepData?.fence_charger_location)
      : localStepData?.wall_mount
      ? ref(localStepData?.wall_mount)
      : ref("");
    // wall_mount
    // const options = [
    //   {
    //     value: "left",
    //     text: "Left wall",
    //     image: "/images/wall-mount/Left.jpg",
    //   },
    //   {
    //     value: "right",
    //     text: "Right wall",
    //     image: "/images/wall-mount/Right.jpg",
    //   },
    //   {
    //     value: "back",
    //     text: "Back wall",
    //     image: "/images/wall-mount/Back.jpg",
    //   },
    //   {
    //     value: "outside",
    //     text: "Outside wall",
    //     image: "/images/wall-mount/Outside.jpg",
    //   },
    // ];
    const options = {
      outside_home_wall: [
        {
          value: "left",
          text: "Left of Home",
          image: "/images/home-wall-mount/left.png",
        },
        {
          value: "right",
          text: "Right of Home",
          image: "/images/home-wall-mount/right.png",
        },
        {
          value: "back",
          text: "Back of Home",
          image: "/images/home-wall-mount/back.png",
        },
        {
          value: "front",
          text: "Front of Home",
          image: "/images/home-wall-mount/front.png",
        },
      ],
      mounted_to_fence: [
        {
          value: "left",
          text: "Left of Home",
          image: "/images/fence-mount/left.png",
        },
        {
          value: "right",
          text: "Right of Home",
          image: "/images/fence-mount/right.png",
        },
        {
          value: "back",
          text: "Back of Home",
          image: "/images/fence-mount/back.png",
        },
        {
          value: "front",
          text: "Front of Home",
          image: "/images/fence-mount/front.png",
        },
      ],
      garage_wall: [
        {
          value: "left",
          text: "Left wall",
          image: "/images/wall-mount/Left.jpg",
        },
        {
          value: "right",
          text: "Right wall",
          image: "/images/wall-mount/Right.jpg",
        },
        {
          value: "back",
          text: "Back wall",
          image: "/images/wall-mount/Back.jpg",
        },
        {
          value: "outside",
          text: "Outside wall",
          image: "/images/wall-mount/Outside.jpg",
        },
      ],
    };
    const componentInfo = {
      outside_home_wall: {
        title: "titlee",
        subtitle:
          "Standing in front and facing your home, which wall do you want your charging station mounted on?",
      },
      mounted_to_fence: {
        title: "titlee",
        subtitle:
          "Standing in front and facing your home, which fence do you want your charging station mounted on?",
      },
      garage_wall: {
        title: "titlee",
        subtitle:
          "Standing in your driveway facing the garage, which wall do you want your charging station mounted on?",
      },
    };
    const selectedChargerLocation = computed(() => {
      if (localStepData?.charger_location) {
        return localStepData.charger_location;
      }
      return null;
    });
    const whichStepIsBefore = () => {
      if (selectedChargerLocation.value !== "garage_wall") {
        stepsStore.prevStep();
      }
    };

    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, () => {
      validated.value = selected.value !== "";
    });

    function updateData() {
      if (selected.value === "") {
        validated.value = false;
      }
      if (selectedChargerLocation.value === "garage_wall") {
        stepsStore.setScheduleStepData({
          wall_mount: selected.value,
        });
      } else if (selectedChargerLocation.value === "outside_home_wall") {
        stepsStore.setScheduleStepData({
          outside_charger_location: selected.value,
        });
      } else if (selectedChargerLocation.value === "mounted_to_fence") {
        stepsStore.setScheduleStepData({
          fence_charger_location: selected.value,
        });
      }
    }
    return {
      selected,
      updateData,
      validated,
      selectedChargerLocation,
      options,
      componentInfo,
      whichStepIsBefore,
    };
  },
};
</script>

<style lang="scss" scoped>
.wall {
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
