<template>
  <div
    class="app-select"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <fieldset style="position: relative">
      <label v-if="label" class="app-select__label" for="select">{{
        label
      }}</label>
      <span class="custom-arrow"></span>
      <select
        id="select"
        :name="name"
        :value="selected"
        :autocomplete="autocomplete"
        @change="onChange($event)"
        class="app-select__select"
      >
        <option value="" disabled selected>{{ defaultOption }}</option>
        <option
          v-for="option in options"
          :value="defaultOption === 'State' ? option.abbreviation : option.name"
          :key="option.abbreviation"
        >
          {{ option.name }}
        </option>
      </select>
      <p class="help-message" v-show="errorMessage || meta.valid">
        {{ errorMessage || successMessage }}
      </p>
    </fieldset>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { toRef } from "vue";

export default {
  name: "AppSelect",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    defaultOption: {
      type: String,
      default: "State",
    },
  },
  setup(props, ctx) {
    const selected = toRef(props, "value");
    const {
      value: inputValue,
      handleChange,
      errorMessage,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    const onChange = (event) => {
      const value = event.target.value;
      handleChange(value);
      ctx.emit("update:modelValue", value);
      ctx.emit("state-updated", value);
    };
    return {
      inputValue,
      handleChange,
      onChange,
      errorMessage,
      meta,
      selected,
    };
  },
};
</script>

<style lang="scss" scoped>
.app-select {
  position: relative;
  display: inline-block;
  margin-bottom: calc(1em * 1.5);
  width: 100%;

  &__label {
    display: block;
    margin-bottom: 12px;
    width: 100%;
    color: #888888;
    font: normal normal 400 20px/24px Montserrat;
    letter-spacing: 1px;
  }

  &__select {
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    padding: 15px 20px;
    outline: none;
    width: 100%;
    background-color: #f2f4f8;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }
}
.custom-arrow {
  border-style: solid;
  border-width: 19px 11px 0 11px;
  border-color: #888888 transparent transparent transparent;
  position: absolute;
  right: 24px;
  bottom: 22.5px;
  transform: translateY(50%);
  pointer-events: none;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.placeholder {
  color: #888888;
}

.help-message {
  position: absolute;
  left: 0;
  margin: 5px 0 0 0;
  font-size: 12px;
}

.app-select.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.app-select.has-error input:focus {
  border-color: var(--error-color);
}

.app-select.has-error .help-message {
  color: var(--error-color);
}
</style>
