<template>
  <div class="timing">
    <h2 class="timing__header">
      Have you taken delivery of your Electric Vehicle yet?
    </h2>
    <div class="timing__body">
      <AppRadioGroup
        id="time"
        v-model="selectedValue"
        :options="eleVehicleDelivery"
      />
      <div class="helpMessageBlock">
        <p
          class="helpMessage"
          :class="{ helpMessage__active: validated === false }"
        >
          This field is required
        </p>
      </div>
      <AppDatePicker
        v-show="selectedValue === 'false'"
        name="vehicle-date"
        placeholder="Expected Delivery Date"
        v-model="selectedDate"
        placeholderByDefault="Expected Delivery Date"
      />
    </div>
    <div class="scheduleStepsWrapper">
      <ScheduleStepsAction @next="updateScheduleData" :validated="validated" />
    </div>
  </div>
</template>

<script>
import AppRadioGroup from "@/components/common/AppRadioGroup";
import { ref, watch, reactive } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import AppDatePicker from "@/components/common/AppDatePicker";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const scheduleStore = useScheduleStore();
export default {
  name: "ScheduleStepsTiming",
  components: {
    AppDatePicker,
    ScheduleStepsAction,
    AppRadioGroup,
  },
  setup() {
    const localStepData = reactive(scheduleStore.scheduleData);
    const selectedValue =
      localStepData?.have_electric_vehicle_yet === "false" ||
      localStepData?.have_electric_vehicle_yet === false
        ? ref(false)
        : localStepData?.have_electric_vehicle_yet === "true" ||
          localStepData?.have_electric_vehicle_yet === true
        ? ref(true)
        : ref("");

    let validated =
      localStepData?.have_electric_vehicle_yet !== undefined &&
      localStepData?.have_electric_vehicle_yet !== ""
        ? ref(true)
        : ref(null);

    watch(selectedValue, () => {
      validated.value = selectedValue.value !== "";
    });

    const eleVehicleDelivery = [
      {
        value: true,
        text: "Yes",
      },
      {
        value: false,
        text: "No",
      },
    ];
    const showCalendar = ref(false);
    const selectedDate = localStepData?.vehicle_delivery_date
      ? ref(localStepData.vehicle_delivery_date)
      : ref("");
    function updateScheduleData() {
      if (selectedValue.value === "") {
        validated.value = false;
      }
      if (selectedValue.value.toString() === "true") {
        scheduleStore.setScheduleStepData({
          have_electric_vehicle_yet: selectedValue.value,
          vehicle_delivery_date: "",
        });
      } else {
        scheduleStore.setScheduleStepData({
          have_electric_vehicle_yet: selectedValue.value,
          vehicle_delivery_date: selectedDate.value,
        });
      }
    }
    return {
      eleVehicleDelivery,
      selectedValue,
      showCalendar,
      selectedDate,
      updateScheduleData,
      validated,
    };
  },
};
</script>

<style lang="scss" scoped>
.timing {
  &__header {
    margin-bottom: 52px;
    font: normal normal bold 30px/50px Montserrat;
    color: var(--base-text-color);
  }
  &__body {
    margin-top: 26px;
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
.scheduleStepsWrapper > div {
  width: 80%;
  @media (max-width: 550px) {
    width: 100%;
  }
}
.scheduleStepsWrapper button {
  margin-right: 0 !important;
  display: none !important;
}
</style>
