<template>
  <div class="terms">
    <div class="terms__subtitle">
      <h2 class="terms__subtitle-title">Terms of Use</h2>
      <p class="terms__subtitle-paragraph">
        Please read and accept to continue. We’ll send you a copy for your
        records.
      </p>
    </div>
    <Form
      @submit="onSubmit"
      :validation-schema="termsSchema"
      @invalid-submit="onInvalidSubmit"
      v-slot="{ errors }"
    >
      <div class="terms__body">
        <p>Buzz Charging Terms of Use</p>
        <br />
        <p class="bold">
          USE OF THE BUZZ CHARGING WEBSITE CONSTITUTES ACCEPTANCE OF THESE TERMS
          AND CONDITIONS
        </p>
        <br />
        <p class="bold">PLEASE READ THESE TERMS CAREFULLY</p>
        <br />
        <p class="bold">Version: July 25, 2022</p>
        <br />
        <div><span class="bold pl-15">1. Acceptance of terms. </span></div>
        <p>
          Using this website (and any related application(s), which is included
          within the definition of the word “website”) constitutes your
          acceptance of these terms and conditions (“Agreement”). Buzz Charging
          may unilaterally update these terms and conditions periodically and
          give notice of the updates on its website. You will be bound by those
          changes. Purchasing an electric vehicle (“EV”) charger from a selling
          automobile dealership also constitutes acceptance of this Agreement.
        </p>
        <br />
        <div>
          <span class="bold pl-15"
            >2. The Website Helps You Schedule an EV Charger Installation With a
            Third-Party Installer.
          </span>
        </div>
        <p>
          This website is provided by Buzz Charging as a convenience and for
          your personal use only, without any additional charge. This website
          helps you to schedule a no-additional cost standard installation for
          the electric vehicle (“EV”) charger for your personal EV by an
          independent contractor in your area (“Installer”). A standard
          installation means:
        </p>
        <br />
        <ul>
          <li>
            EV Charger is mounted to a wall. The Installer will charge extra to
            mount the EV Charger on a pedestal.
          </li>
          <li>
            EV Charger is located within 25 feet from the main utility panel.
          </li>
          <li>Main Utility Panel has 200 amp service.</li>
          <li>
            Main Utility Panels has space in it for an additional 60 amp
            breaker.
          </li>
          <li>
            By authorizing the work on the property, you warrant and represent
            you have the requisite authority to authorize the work and have
            obtained all necessary approvals (including building permits).
            Neither Buzz Charging the selling automobile dealership nor the
            Installers shall have any liability whatsoever for installations
            that are completed on unauthorized properties.
          </li>
          <li>
            You will be sent a confirmation of an installation date. As the date
            draws nearer, we will narrow the installation to a 4-hour window.
            The Installer requires that someone over the age of 18 be present
            while the installation is being performed. If the Installer is
            unable to install the EV Charger because the Installer cannot access
            the location, the Installer will charge you a rescheduling fee.
          </li>
          <li>
            The EV Charger will be shipped to your address prior to the
            installation. Please have the EV Charger accessible for the
            Installer at the date and time of the installation.
          </li>
          <li>
            The Installer will bring all reasonably necessary installation
            materials.
          </li>
          <li>
            There will be an additional charge for runs longer than 25 feet
            between the EV Charger and the Main Utility Panel, Upgrades of
            Utility Service, Installation of Sub Panels, Trench Digging,
            Pedestals and other non standard installation details. Installers do
            not dig through driveways, walks, concrete, stone or asphalt.
          </li>
          <li>
            Non standard installations will be quoted via an incremental
            estimate. Customer is expected to pay the incremental estimate
            through an emailed QuickBooks (or similar) invoice, prior to
            installation date, or the installation will be postponed.
          </li>
        </ul>
        <br />
        <p>This website is protected by copyright.</p>
        <br />
        <div>
          <span class="bold pl-15"
            >3. The Installers are independent contractors.
          </span>
        </div>
        <p>
          The Installers are not Buzz Charging employees, agents or
          representatives. The Installers act solely for the Installer’s own
          account. The Installers may not change this Agreement. The Installers
          have no authority to bind Buzz Charging in any manner whatsoever. Buzz
          Charging is not responsible for the Installer’s work.
        </p>
        <br />
        <div>
          <span class="bold pl-15"
            >4. Limitation of Warranties and damages.
          </span>
        </div>
        <p class="bold">
          BUZZ CHARGING MAKES NO WARRANTY, EXPRESS OR IMPLIED, CONCERNING THE
          WEBSITE AND THE EV CHARGER, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
          WHICH IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS ARE HEREBY
          EXPRESSLY EXCLUDED AND WAIVED BY YOU. THE WEBSITE AND THE EV CHARGER
          SHALL ONLY BE USED ON AN “AS IS” AND “WHERE IS” BASIS.
        </p>
        <br />
        <p class="bold">
          BUZZ CHARGING TOTAL AND CUMULATIVE LIABILITY, IF ANY, ARISING OUT OF
          OR IN ANY WAY RELATED TO THIS WEBSITE, EV CHARGER, THIS AGREEMENT AND
          THE INSTALLERS SHALL BE LIMITED TO MONEY DAMAGES IN AN AMOUNT NOT TO
          EXCEED THE PRICE YOU PAID THE EV DEALERSHIP FOR THE EV CHARGER.
        </p>
        <br />
        <p class="bold">
          SOME JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OR LIMITATION OF
          LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. IN SUCH
          JURISDICTIONS, BUZZ CHARGING LIABILITY IS LIMITED TO THE GREATEST
          EXTENT PERMITTED BY LAW.
        </p>
        <br />
        <div><span class="bold pl-15">5. Limitation of Liability. </span></div>
        <p class="bold">
          IN NO EVENT SHALL BUZZ CHARGING BE LIABLE FOR ANY SPECIAL, INDIRECT OR
          CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RELATING TO THIS
          WEBSITE, EV CHARGER AND/OR THE INSTALLERS.
        </p>
        <br />
        <div>
          <span class="bold pl-15"
            >6. Use of the Information You Provide.
          </span>
        </div>
        <p>
          The information you provide to Buzz Charging through the website will
          be disclosed to the relevant Installer to enable the Installer to
          install the EV Charger. You should only transmit information necessary
          to schedule an installation of your EV Charger. If you do not provide
          accurate information, there will likely be unforeseen problems with
          the installation. You understand that Buzz Charging and the Installer
          will be relying upon the accuracy of the information that you provide.
        </p>
        <br />
        <p>
          To the extent that you provide Buzz Charging with Personally
          Identifiable Information (“PII”), it will be handled in accordance
          with our Privacy Policy (which may be updated from time to time).
        </p>
        <br />
        <div>
          <span class="bold pl-15">7. Disclosure to EV dealership. </span>
        </div>
        <p>
          When the Installer completes the installation, the Installer will
          provide proof of completion to the selling automobile dealership and
          Buzz Charging.
        </p>
        <br />
        <div>
          <span class="bold pl-15">8. Restrictions on using the website. </span>
        </div>
        <br />
        <div>
          You will not use the website:
          <ul>
            <li>To violate this Agreement;</li>
            <li>For any commercial purpose;</li>
            <li>To obtain another person’s PII;</li>
            <li>To violate any laws;</li>
            <li>
              To harass, threaten, intimidate, impersonate, or attempt to
              impersonate, any other person, falsify your contact or other
              information, misrepresent a relationship with any person or
              entity, or otherwise attempt to mislead others as to your
              identity;
            </li>
            <li>
              To knowingly provide or submit false or misleading information on
              or through the website;
            </li>
            <li>
              To attempt to gain unauthorized access to the website, other user
              accounts, or other computer systems or networks connected to the
              website;
            </li>
            <li>
              To transmit any materials that are encrypted, contain
              self-executing code or contain viruses, such as Trojan horses,
              worms, time bombs, spiders, cancelbots or other computer
              programming routines; or
            </li>
            <li>
              Probe, scan or test the vulnerability of this website, nor breach
              the security or authentication measures on this website. You may
              not reverse look-up, trace or seek to trace any information on any
              other user, or exploit this website in any way to reveal any
              information, including but not limited to personal identification
              or information other than your own information.
            </li>
          </ul>
        </div>
        <br />
        <div>
          <span class="bold pl-15"
            >9. Third Parties May Have Rights Under This Agreement.
          </span>
        </div>
        <p>
          Some of the provisions of this Agreement are for the benefit of third
          parties. These third parties may rely upon this Agreement.
        </p>
        <br />
        <div>
          <span class="bold pl-15"
            >10. Notices May Be Provided Electronically.
          </span>
        </div>
        <p>
          By using this website, you consent to receive electronic
          communications from us via email or directly to your account. The only
          way you can revoke this consent is to stop using this website and
          inform us in writing. All notices to us will be provided by either
          sending: (i) an email to
          <a href="mailto:support@buzzcharging.com">support@buzzcharging.com</a>
          or (ii) a letter, first class certified mail, to Buzz Charging 71
          Dolphin Rd., Bristol, CT 06010; Attn: User Services. Notices sent by
          mail will be deemed delivered upon the earlier of the verification of
          delivery or two (2) business days after being sent.
        </p>
        <br />
        <div><span class="bold pl-15">11. Choice of law. </span></div>
        <p>
          This Agreement shall be governed by, construed and enforced in
          accordance with the substantive laws of the State of Connecticut
          (excluding any otherwise applicable choice of law rules) and the
          judicial decisions which interpret and apply them.
        </p>
        <br />
        <div><span class="bold pl-15">12. Statute of Limitations. </span></div>
        <p>
          Notwithstanding any statutory provisions governing statutes of
          limitations, no claim, regardless of form, on or relating to the
          website, the EV Charger, this Agreement or the Installers may be
          brought more than one (1) year after the date the cause of action
          arose.
        </p>
        <br />
        <div>
          <span class="bold pl-15"
            >13. Arbitration by American Arbitration Association.
          </span>
        </div>
        <p>
          Any controversy or claim arising out of or relating to this Agreement,
          or the breach thereof, this website, the EV Charger or the Installer
          shall be settled by a single arbitrator on a “documents only” basis in
          accordance with the Consumer Arbitration Rules of the American
          Arbitration Association and judgment upon the award rendered by the
          arbitrator may be entered in any court having jurisdiction thereof.
          The arbitration shall be conducted in the English language in Hartford
          County, Connecticut. The seat of the arbitration shall be deemed to be
          Connecticut and the arbitration shall be governed by Connecticut law.
          The arbitrator shall have the exclusive jurisdiction to determine his
          jurisdiction and no party may bring a class action in this
          arbitration. The arbitration may not be consolidated with other
          arbitrations. Unless the parties otherwise specifically agree, there
          will be (i) no discovery and (ii) no mediation. If your claim is
          within the small claims jurisdictional limit of the state of your
          residence, you may elect to bring your claim there.
        </p>
        <br />
        <div>
          <span class="bold pl-15">14. Entire Agreement and Amendments. </span>
        </div>
        <p>
          This Agreement contains the entire understanding of the parties. There
          are no oral understandings, terms or conditions, and no party has
          relied upon any representation, express or implied, not contained in
          this Agreement. This Agreement may not be amended in any respect
          whatsoever except by a further agreement, in writing, fully executed
          by Customer and Buzz Charging (except to the extent Buzz Charging
          modifies the Agreement for all customers on its website).
        </p>
      </div>
      <div class="checkbox">
        <Field
          name="termsAccepted"
          type="checkbox"
          id="termsAccepted"
          :value="true"
          v-model="isTermsAccepted"
        />
        <label for="termsAccepted"> I agree to terms and conditions </label>
        <div class="help-message" :class="{ 'has-error': !!errors }">
          {{ errors.termsAccepted }}
        </div>
      </div>
      <div class="terms__actions">
        <AppButton @click="prevAction"> Previous </AppButton>
        <AppButton type="submit"> Next </AppButton>
      </div>
    </Form>
  </div>
</template>

<script>
import * as Yup from "yup";
import { Form, Field } from "vee-validate";
import { useScheduleStore } from "@/store/ScheduleStepsData";
import AppButton from "@/components/common/AppButton";
import { reactive, ref } from "vue";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsTerms",
  components: { AppButton, Form, Field },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const isTermsAccepted = localStepData?.termsAccepted
      ? ref(localStepData?.termsAccepted)
      : ref(false);
    const termsSchema = Yup.object().shape({
      termsAccepted: Yup.bool()
        .oneOf([true], "Required field!")
        .required("Required field!"),
    });

    function onInvalidSubmit() {
      const submitBtn = document.querySelector(".app-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    }

    function onSubmit(val) {
      stepsStore.setScheduleStepData({
        termsAccepted: val.termsAccepted.toString() === "true",
      });
      stepsStore.nextStep();
    }
    return {
      termsSchema,
      onInvalidSubmit,
      onSubmit,
      isTermsAccepted,
    };
  },
  methods: {
    prevAction() {
      this.$emit("prev");
      stepsStore.prevStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.terms {
  &__subtitle {
    color: var(--base-text-color);
    margin-bottom: 50px;
    &-title {
      font: normal normal bold 30px/68px Montserrat;
      @media screen and (max-width: 550px) {
        font: normal normal bold 20px/38px Montserrat;
      }
    }
    &-paragraph {
      font: normal normal medium 20px/68px Montserrat;
    }
  }
  &__body {
    border: 1px solid #eeeeee;
    width: inherit;
    box-shadow: inset 2px 5px 6px #00000029;
    font: normal 20px/25px Montserrat;
    color: var(--base-text-color);
    max-height: 845px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 24px;
    margin-bottom: 50px;
    ul {
      list-style-type: disc;
      padding-left: 45px;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 80%;
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
    & button {
      max-width: 398px;
      width: 100%;
    }
    @media screen and (max-width: 920px) and (min-width: 550px) {
      & > :first-child {
        margin-right: 8px;
      }
      & > :last-child {
        margin-left: 8px;
      }
    }
    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
      & button {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 10px;
      }
    }
  }
}
.line-break {
  content: "";
  margin: 0 0 42px 0;
  display: block;
}
.bold {
  font-weight: 600;
}
.pl-15 {
  padding-left: 15px;
}
.checkbox {
  display: flex;
  align-items: center;
  position: relative;
}
input[type="checkbox"] {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border: 1px solid var(--base-text-color);
  accent-color: var(--buzz-charging-dark-blue);
}

label {
  font: normal normal medium 20px/35px Montserrat;
  color: var(--base-text-color);
}
.help-message {
  position: absolute;
  left: 4px;
  margin: 0;
  font-size: 14px;
  bottom: -30%;
}

.has-error {
  background-color: #fff;
  color: var(--error-color);
}
</style>
