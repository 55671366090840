<template>
  <div class="breaker">
    <h2 class="breaker__subtitle">
      Do you have room in your Main Electrical Panel for an additional breaker?
    </h2>
    <div class="breaker__body">
      <AppRadioGroup id="panel-breaker" :options="options" v-model="selected" />
      <img src="/images/Breaker.jpg" alt="" />
    </div>
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        This field is required
      </p>
    </div>
    <ScheduleStepsAction @next="updateData" :validated="validated" />
    <AdditionalCostsModal
      v-if="showModal"
      @close="showModal = false"
      @agreed="goToNextStep"
      customText="If no room for additional breaker"
    />
  </div>
</template>

<script>
import AppRadioGroup from "@/components/common/AppRadioGroup";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import AdditionalCostsModal from "@/components/layouts/AdditionalCostsModal";
import { ref, reactive, watch } from "vue";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsElectricalPanelBreaker",
  components: { AppRadioGroup, ScheduleStepsAction, AdditionalCostsModal },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected =
      localStepData?.room_in_main_panel !== undefined
        ? localStepData.room_in_main_panel === null
          ? ref("don't know")
          : ref(localStepData.room_in_main_panel)
        : ref("");
    let showModal = ref(false);
    const options = [
      {
        value: true,
        text: "Yes",
      },
      {
        value: false,
        text: "No",
      },
      {
        value: "don't know",
        text: "Don't know",
      },
    ];
    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, (val) => {
      if (val === "") {
        validated.value = false;
      } else if (val === false || val === "false") {
        validated.value = null;
      } else {
        validated.value = true;
      }
    });

    const goToNextStep = () => {
      validated.value = true;
      showModal.value = false;
      stepsStore.nextStep();
    };

    function updateData() {
      if (selected.value === "") {
        validated.value = false;
      }
      if (selected.value === false || selected.value === "false") {
        showModal.value = true;
      }
      stepsStore.setScheduleStepData({
        room_in_main_panel:
          selected.value === "don't know"
            ? null
            : selected.value.toString() === "true",
      });
    }

    return {
      selected,
      options,
      updateData,
      showModal,
      validated,
      goToNextStep,
      localStepData,
    };
  },
};
</script>

<style lang="scss" scoped>
.breaker {
  // width: 80%;
  @media screen and (max-width: 768px) {
    width: inherit;
  }
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 27px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
    display: flex;
    margin-bottom: 70px;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    &:after {
      content: " ";
    }

    & > img {
      @media screen and (max-width: 768px) {
        height: 100%;
        width: 100%;
      }
    }
  }
}
.steps {
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
