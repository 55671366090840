<template>
  <fieldset
    :id="id"
    class="wrapper"
    :class="fullWidth ? 'width-full' : 'not-full-width'"
  >
    <legend v-if="label">{{ label }}</legend>
    <label
      v-for="(option, index) in options"
      :key="option.text"
      class="form-control radio"
    >
      <input
        :id="id + index"
        :name="id"
        type="radio"
        :value="option.value"
        :checked="option.value === modelValue"
        :disabled="disabled"
        :required="required"
        @change="updateValue(option.value)"
      />
      <div class="image-wrapper" v-if="option && option.image.length">
        <label :for="id + index">{{ option.text }}</label>
        <img class="image-wrapper__image" :src="option.image" alt="" />
      </div>
      <slot v-if="option.value === modelValue" :name="option.value" />
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "AppRadioGroupWithImage",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, ctx) {
    const updateValue = (value) => {
      ctx.emit("update:modelValue", value);
    };
    return {
      updateValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.form-control {
  display: flex;
  align-items: center;
  margin: 26px 16px 16px 0;
  flex-direction: column;
  //flex-basis: 33.3%;
  max-width: 398px;
  width: 100%;
  cursor: pointer;
  @media screen and(max-width: 1550px) {
    flex-basis: 400px;
  }
  @media screen and(max-width: 550px) {
    margin: 26px 0 16px 0;
  }
}
input[type="radio"] {
  position: relative;
  appearance: none;
  align-self: baseline;
  background-color: #fff;
  font: inherit;
  width: 20px;
  height: 20px;
  min-height: 20px;
  border: 1px solid #707070;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  color: #fff;
}
input[type="radio"]::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);

  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--buzz-charging-dark-blue);
}
input[type="radio"]:checked::before {
  transform: translate(-50%, -50%) scale(1.15);
}
label {
  color: #888888;
  display: block;
  font: 20px Montserrat;
}

.radio {
  display: block;
  padding: 20px;
  border: 2px solid #888888;
  border-radius: 15px;
  max-width: 394px;
  height: 425px;
  width: 100%;
  min-height: 100%;
}

.image-wrapper {
  padding: 0 50px;
  text-align: center;
  position: relative;
  &__image {
    max-width: 100%;
    max-height: 100%;
    margin-top: 75px;
    width: 85%;
  }
}
.width-full {
  width: 100%;
}
.not-full-width {
  width: 80%;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
</style>
