<template>
  <div class="personal-info">
    <h2 class="personal-info__subtitle">
      Please provide your contact information
    </h2>
    <Form
      class="personal-info__form"
      @submit="onSubmit"
      :validation-schema="contactInformationSchema"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="personal-info__name-section">
        <AppInput
          name="firstName"
          placeholder="First name "
          label="Name"
          :value="localStepData.firstName"
          required
        />
        <AppInput
          name="lastName"
          placeholder="Last name"
          :value="localStepData.lastName"
          required
        />
      </div>
      <AppInput
        name="phone"
        placeholder="xxx-xxx-xxxx"
        label="Phone"
        class="personal-info__phone"
        :value="localStepData.phone"
        required
      />
      <AppInput
        name="street"
        placeholder="Street Address"
        label="Address"
        :value="localStepData.street"
        autocomplete="address-line1"
        required
      />
      <AppInput
        name="street2"
        placeholder="Address Line 2"
        :value="localStepData.street2"
      />
      <div class="single-line">
        <AppInput
          name="city"
          placeholder="City/Town"
          autocomplete="address-level2"
          :value="localStepData.city"
        />
        <AppSelect
          :options="usState"
          name="state"
          :value="selectedState"
          label=""
          autocomplete="address-level1"
          @state-updated="stateUpdated"
        />
      </div>

      <div class="single-line">
        <AppInput
          name="zipcode"
          placeholder="Zip Code"
          autocomplete="postal-code"
          :value="selectedZipcode"
          @change="zipUpdated"
        />
        <AppInput
          name="country"
          placeholder="United States"
          disabled
          v-model="defaultCountry"
        />
      </div>
      <AppSelect
        v-if="selectedZipcode"
        defaultOption="Choose a company"
        :options="utilityCompanies"
        name="utility"
        :value="selectedUtility"
        label="Select Your Utility Provider"
        @state-updated="utilityUpdated"
      />

      <div
        class="personal-info__rebates"
        style="text-align: right; margin-bottom: 20px"
      >
        <a
          :class="{ 'inactive-link': !selectedState }"
          :title="!selectedState ? 'Choose your state first' : ''"
          class="personal-info__rebates-link"
          :href="rebatesLinkText"
          target="_blank"
          >See Rebates in Your State!</a
        >
      </div>

      <AppInput
        name="dealer"
        placeholder="Auto Dealer Name"
        label="Auto Dealer Name"
        required
        :value="localStepData.dealer"
      />
      <!-- <div class="personal-info__blockWithImage">
        <AppInput
          name="vin"
          label="VIN Number"
          placeholder="VIN Number"
          :value="localStepData.vin"
          required
          subLabel="Please enter the 17 character VIN of the recently purchased vehicle. 
          See dealership paperwork, insurance card, registration, or the diagram below for the most common VIN locations."
        />
        <div class="personal-info__images">
          <img
            src="/images/VinImage.svg"
            alt=""
            class="personal-info__picture personal-info__picture--first"
          />
          <img
            src="/images/VinImage2.png"
            alt=""
            class="personal-info__picture personal-info__picture--second"
          />
        </div>
      </div> -->
      <div class="personal-info__blockWithImage">
        <AppInput
          name="confirmation"
          label="Confirmation #:"
          placeholder="Confirmation #:"
          :value="localStepData.confirmation"
          required
          subLabel="Your confirmation number will be printed and emailed by the Finance office.  In order to verify receipt of your payment and product, we’ll need this number input correctly."
        />
      </div>
      <div class="personal-info__actions">
        <AppButton @click="prevAction"> Previous </AppButton>
        <AppButton type="submit"> Next </AppButton>
      </div>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
// import { ref } from "vue/dist/vue";
import * as Yup from "yup";
import AppInput from "@/components/common/AppInput";
import { statesData } from "@/common/USstates";
import AppSelect from "@/components/common/AppSelect";
import { useScheduleStore } from "@/store/ScheduleStepsData";
import AppButton from "@/components/common/AppButton";
import { ref, reactive, onMounted, watch } from "vue";
import { useAuthStore } from "@/store/AuthStore";
import { autofill } from "@mapbox/search-js-web";
import apiAxios from "@/api";

const authStore = useAuthStore();

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsPersonalInfo",
  components: { AppButton, AppSelect, AppInput, Form },
  setup() {
    const usState = statesData;
    const localStepData = reactive(stepsStore.scheduleData);
    const defaultCountry = "United States";
    const rebateLinkMainPart = "https://programs.dsireusa.org/system/program/";
    const selectedState = ref(localStepData.state);
    const rebatesLinkText = ref(
      rebateLinkMainPart + selectedState.value?.toLowerCase()
    );
    const stateUpdated = (val) => {
      selectedState.value = val;
      rebatesLinkText.value =
        rebateLinkMainPart + selectedState.value.toLowerCase();
    };

    const confirmationId = localStorage.getItem("confid");
    if (confirmationId) {
      stepsStore.scheduleData.confirmation = confirmationId;
      localStorage.removeItem("confid");
    }

    const utilityCompanies = ref([]);
    const selectedZipcode = ref(stepsStore.scheduleData.zipcode);
    const zipUpdated = (val) => {
      selectedZipcode.value = val.target.value;
      getUtilityCompanies(selectedZipcode.value);
    };
    async function getUtilityCompanies(zip) {
      if (zip?.length !== 5) return;
      await apiAxios.User.getUtilityCompany({ zip }).then((response) => {
        const duplicatedCompany = response.utilityCompanies
          .map((company) => company.utility_name)
          .filter((el, index, arr) => arr.indexOf(el) !== index);
        utilityCompanies.value = response.utilityCompanies.map(
          ({ utility_name: name, id: abbreviation, service_type }) => {
            const isDuplicate = duplicatedCompany.includes(name);
            const company = {
              name: isDuplicate ? `${name} (${service_type})` : name,
              abbreviation,
            };
            return company;
          }
        );
        return response.utilityCompanies;
      });
    }
    const selectedUtility = ref(localStepData.utility);
    watch(selectedZipcode, () => {
      selectedUtility.value = "";
    });
    const utilityUpdated = (val) => {
      selectedUtility.value = val;
    };
    const contactInformationSchema = Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phone: Yup.string()
        .matches(/^\+?(?:[0-9]?){6,14}[0-9]$/, "Invalid phone number")
        .transform((value) => value.replace("+", ""))
        .required("Phone number is required"),
      street: Yup.string().required("This field is required"),
      street2: Yup.string(),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      utility: Yup.string(),
      dealer: Yup.string().required("This field is required"),
      zipcode: Yup.string()
        .required("Zipcode is required")
        .length(5, "Zipcode should have 5 digits")
        .matches(/^\d+$/, "Zipcode should have digits only"),
      // vin: Yup.string(),
      // .required("VIN is required")
      // .matches(
      //   /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/,
      //   "VIN must be 17 characters"
      // ),
      confirmation: Yup.string().required("This field is required"),
      country: Yup.string().nullable().notRequired(),
    });
    function onInvalidSubmit() {
      const submitBtn = document.querySelector(".app-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
      let domRect = document
        .querySelector(".has-error")
        .getBoundingClientRect();
      window.scrollTo(
        domRect.left + document.documentElement.scrollLeft,
        domRect.top + document.documentElement.scrollTop
      );
    }

    function onSubmit(values) {
      values.name = values.firstName + " " + values.lastName;
      authStore.updateUserProfile(values);
      stepsStore.setScheduleStepData({
        ...values,
        country: "United States",
      });
      stepsStore.nextStep();
    }

    onMounted(() => {
      const ACCESS_TOKEN = process.env.VUE_APP_MAPBOX_API_KEY;
      autofill({
        accessToken: ACCESS_TOKEN,
        options: { country: "us" },
      });
      getUtilityCompanies(localStepData?.zipcode);
    });

    return {
      contactInformationSchema,
      onInvalidSubmit,
      onSubmit,
      usState,
      defaultCountry,
      localStepData,
      rebatesLinkText,
      selectedState,
      stateUpdated,
      selectedZipcode,
      getUtilityCompanies,
      utilityCompanies,
      selectedUtility,
      utilityUpdated,
      zipUpdated,
    };
  },
  methods: {
    nextAction() {
      this.$emit("next");
      stepsStore.nextStep();
    },
    prevAction() {
      this.$emit("prev");
      stepsStore.prevStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-info {
  width: 80%;
  @media screen and (max-width: 1200px) {
    width: inherit;
  }
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal 400 20px/30px Montserrat;
    margin-bottom: 50px;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__name-section {
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }
  &__name-section > div:first-child {
    margin-right: 16px;
    @media screen and (max-width: 1200px) {
      margin-right: 0;
    }
  }
  &__phone {
    width: calc(50% - 8px);
  }
  &__blockWithImage {
    margin-top: 8px;
  }
  &__images {
    display: flex;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  &__picture {
    display: block;
    &--first {
      width: 58%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &--second {
      width: 42%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    max-width: 756px;
    height: 100%;
    max-height: 461px;
    margin-top: 8px;
    margin-bottom: 50px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    & button {
      max-width: 398px;
      width: 100%;
    }
    @media screen and (max-width: 920px) and (min-width: 550px) {
      & > :first-child {
        margin-right: 8px;
      }
      & > :last-child {
        margin-left: 8px;
      }
    }
    @media (max-width: 550px) {
      flex-direction: column;
      & button {
        margin-bottom: 10px;
      }
    }
  }
  &__rebates {
    max-width: 360px;
    background: var(--buzz-charging-dark-blue);
    border: none;
    border-radius: 7px;
    margin-left: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:hover,
    :active {
      background: var(--buzz-charging-yellow);
    }

    &-link {
      width: 100%;
      text-align: center;
      text-decoration: none;
      font: normal normal bold 18px Montserrat;
      padding: 16px 32px;
      display: inline-block;
      color: var(--buzz-charging-yellow);

      @media screen and (max-width: 500px) {
        font-size: 20px;
      }

      &:hover,
      :active {
        color: var(--buzz-charging-dark-blue);
      }
    }
  }
}
.single-line {
  display: flex;
  align-items: flex-start;
  & > div:first-child {
    margin-right: 16px;
  }
}
.inactive-link {
  cursor: not-allowed !important;
  opacity: 0.6;
  a {
    pointer-events: none;
    text-decoration: none;
  }
}
</style>
