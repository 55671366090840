<template>
  <div class="date-picker">
    <fieldset @click="showModal = true">
      <label class="date-picker__label" :for="name">{{ label }}</label>
      <input
        class="date-picker__input"
        :name="name"
        :id="name"
        type="text"
        disabled
        :placeholder="modelValue === '' ? placeholderByDefault : modelValue"
      />
      <span class="date-picker__icon"></span>
    </fieldset>

    <AppModalCalendar
      :modelValue="modelValue"
      :id="name"
      v-if="showModal"
      @close="showModal = false"
      @update="updateValue"
      :nextAvailableDate="nextAvailableDate"
      :disabledDates="disabledDates"
      :available-dates="availableDates"
    />
  </div>
</template>

<script>
import AppModalCalendar from "@/components/common/AppModalCalendar";
import { ref } from "vue";
export default {
  name: "AppDatePicker",
  components: { AppModalCalendar },
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    placeholderByDefault: {
      type: String,
      default: "mm/dd/yy",
    },
    nextAvailableDate: {
      type: Date,
    },
    disabledDates: {
      type: Object,
    },
    availableDates: {
      type: Object,
    },
  },
  setup(props, ctx) {
    const updateValue = (value) => {
      ctx.emit("update:modelValue", value);
    };
    const showModal = ref(false);

    return {
      showModal,
      updateValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  position: relative;
  display: inline-block;
  margin-bottom: calc(1em * 1.5);
  width: fit-content;
  min-width: 330px;
  appearance: none;
  @media (max-width: 500px) {
    padding-right: 40px;
    min-width: 270px;
  }

  &__label {
    display: block;
    margin-bottom: 18px;
    width: 100%;
    font: normal normal bold 30px/68px Montserrat;
    color: #888888;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__sub-label {
    display: block;
    margin-bottom: 18px;
    width: 100%;
    color: #888888;
    font: italic normal medium 20px/30px Montserrat;
  }

  &__input {
    box-sizing: border-box;
    padding: 15px 20px;
    outline: none;
    width: 100%;
    border: none;
    max-width: 280px;
    border-bottom: 1px solid #707070;
    color: var(--base-text-color);
    font: 20px Montserrat;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    @media (max-width: 500px) {
      font-size: 16px;
      padding: 12px 17px;
    }

    &:disabled {
      background: #fff;
    }
  }
  &__icon {
    background-image: url("/images/calendar-icon.svg");
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
    content: " ";
    fill: #00a089;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 296px;
    @media (max-width: 500px) {
      display: block;
      right: 0px;
      left: unset;
    }
  }
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.help-message {
  position: absolute;
  left: 0;
  margin: 0;
  font-size: 14px;
}
.date-picker.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.date-picker.has-error input:focus {
  border-color: var(--error-color);
}

.date-picker.has-error .help-message {
  color: var(--error-color);
}
</style>
