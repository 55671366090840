<template>
  <div
    class="TextPasswordInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label class="TextPasswordInput__label" :for="name">
      {{ label }}<span v-if="required" style="color: red">*</span>
    </label>
    <input
      class="TextPasswordInput__input"
      :name="name"
      :id="name"
      :type="isPasswordVisible ? 'text' : 'password'"
      :value="inputValue"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="handleBlur"
    />

    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
    <span
      v-if="withIcon"
      class="TextPasswordInput__visibility-icon"
      :class="{ passwordVisible: isPasswordVisible }"
      @click="isPasswordVisible = !isPasswordVisible"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40.5"
        height="27"
        viewBox="0 0 40.5 27"
      >
        <path
          id="Icon_awesome-eye"
          data-name="Icon awesome-eye"
          d="M40.255,16.973A22.552,22.552,0,0,0,20.25,4.5,22.555,22.555,0,0,0,.245,16.974a2.275,2.275,0,0,0,0,2.052A22.552,22.552,0,0,0,20.25,31.5,22.555,22.555,0,0,0,40.255,19.026,2.275,2.275,0,0,0,40.255,16.973ZM20.25,28.125A10.125,10.125,0,1,1,30.375,18,10.125,10.125,0,0,1,20.25,28.125Zm0-16.875a6.7,6.7,0,0,0-1.78.266,3.364,3.364,0,0,1-4.7,4.7,6.735,6.735,0,1,0,6.484-4.97Z"
          transform="translate(0 -4.5)"
          fill="#888"
        />
      </svg>
    </span>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { toRef } from "vue";

export default {
  name: "AppPasswordInput",
  props: {
    type: {
      type: String,
      default: "password",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
    };
  },
  setup(props) {
    const name = toRef(props, "name");
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      initialValue: props.value,
    });
    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  methods: {
    onChange() {
      this.$emit("change");
    },
    async onBlur(e, validate) {
      this.focused = false;
      this.$emit("blur");
      await validate(this.value);
    },
    onInput($event) {
      this.$emit("input", $event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.TextPasswordInput {
  position: relative;
  display: inline-block;
  margin-bottom: 2em;
  width: 100%;

  &__label {
    display: block;
    font: normal normal 400 20px/24px Montserrat;
    margin-bottom: 12px;
    width: 100%;
    color: #888888;
  }

  &__input {
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    padding: 15px 20px;
    outline: none;
    width: 100%;
    background-color: #f2f4f8;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &::placeholder {
      color: var(--placeholder-color);
    }
  }

  &__visibility-icon {
    position: absolute;
    margin-left: 16px;
    cursor: pointer;
    bottom: 22.5px;
    transform: translateY(50%);
    @media (max-width: 500px) {
      margin-left: 8px;
      svg {
        max-width: 30px;
      }
    }
    svg {
      path {
        fill: var(--base-text-color);
        transition: all 0.3s;
      }
    }
    &:hover {
      svg {
        path {
          fill: #555;
        }
      }
    }
  }
  .passwordVisible {
    svg {
      path {
        fill: var(--buzz-charging-dark-blue);
      }
    }
  }
}

.help-message {
  position: absolute;
  left: 0;
  margin: 5px 0 24px 0;
  font-size: 12px;
}
.TextPasswordInput.has-error {
  margin-bottom: calc(1.5em * 2);
}

.TextPasswordInput.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextPasswordInput.has-error input:focus {
  border-color: var(--error-color);
}

.TextPasswordInput.has-error .help-message {
  color: var(--error-color);
}
</style>
