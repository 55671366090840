<template>
  <div class="congrats">
    <h2 class="congrats__title">CONGRATULATIONS!</h2>
    <h3 class="congrats__sub-title">
      {{ congratText.mainText }}
    </h3>
    <h2 class="congrats__date">{{ orderDate }}</h2>
    <!-- <p class="congrats__description">
      <span
        v-if="typeOfProperty === 'rent'"
        style="color: red; font-weight: bold"
        >Reminder:</span
      >
      {{ congratText.reminder }}
    </p> -->
    <AppButton class="congrats__actions" @click="finishInstallationOrder"
      >Finish</AppButton
    >
  </div>
</template>

<script>
import AppButton from "@/components/common/AppButton";
import { useScheduleStore } from "@/store/ScheduleStepsData";
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsCongrats",
  components: { AppButton },
  setup() {
    const router = useRouter();
    const localStepData = reactive(stepsStore.scheduleData);
    const congratTextOptions = {
      default: {
        mainText: "Your installation request has been sent.",
        reminder:
          "*In order to confirm your installation you will receive an email and text link with the photos that are required by our installer to confirm your job. Please note that your installation cannot be confirmed until these are received and reviewed. Thank you.",
      },
      rent: {
        mainText: "Thank you for registering your installation.",
        reminder:
          "Your installation is NOT confirmed until we receive the permission document back from your property owner/property management contact.",
      },
    };
    const typeOfProperty = localStorage.typeOfProperty;
    const congratText =
      typeOfProperty === "rent"
        ? congratTextOptions.rent
        : congratTextOptions.default;
    const orderDate = computed(() => {
      // const date = new Date(localStepData.createdAt);
      const date = new Date(stepsStore.scheduleDate);
      return date.toLocaleString("en-us", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    });
    function finishInstallationOrder() {
      stepsStore.resetSteps();
      router.push({
        path: `/profile`,
      });
    }
    return {
      finishInstallationOrder,
      orderDate,
      localStepData,
      congratText,
      typeOfProperty,
    };
  },
};
</script>

<style lang="scss" scoped>
.congrats {
  color: var(--base-text-color);
  text-align: center;
  max-width: 810px;
  width: 100%;
  margin: 0 auto;
  &__title {
    font: normal normal bold 57px/68px Futura;
    margin-bottom: 32px;
    @media screen and (max-width: 550px) {
      font: normal normal bold 28px/48px Futura;
    }
  }
  &__sub-title {
    font: normal normal 600 35px/43px Montserrat;
    margin-bottom: 48px;
    @media screen and (max-width: 550px) {
      font: normal normal bold 24px/48px Futura;
    }
  }
  &__date {
    font: normal normal 600 60px/73px Montserrat;
    color: var(--buzz-charging-yellow);
    margin-bottom: 48px;
    @media (max-width: 500px) {
      font-size: 40px;
      line-height: 1.5;
    }
  }
  &__description {
    font: normal normal normal 20px/35px Montserrat;
    margin-bottom: 48px;
  }
  &__actions {
    max-width: 398px;
    width: 100%;
    margin: 0 auto;
    background: var(--buzz-charging-yellow);
    color: var(--buzz-charging-dark-blue);
  }
}
</style>
