<template>
  <div class="progressbar" :id="id">
    <span
      class="progressbar__line"
      :style="{ width: progressCompleted }"
    ></span>
    <span
      class="progressbar__dot"
      :style="{ left: `calc(${progressCompleted} - 35px/2)` }"
    ></span>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "AppProgressBar",
  props: {
    progress: { type: Number, default: 0 },
    id: { type: String, defualt: "" },
  },
  setup(props) {
    const progressCompleted = computed(() => {
      return `${props.progress}%`;
    });

    return {
      progressCompleted,
    };
  },
};
</script>

<style lang="scss" scoped>
.progressbar {
  height: 20px;
  position: relative;
  background: #fff;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #dddddd;
  border-radius: 20px;

  &__line {
    display: block;
    height: 100%;
    background: var(--buzz-charging-yellow);
    position: relative;
    overflow: hidden;
    border-radius: 20px;
  }

  &__dot {
    position: absolute;
    content: " ";
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--buzz-charging-dark-blue);
    top: calc(-100% + 8px);
    box-shadow: 0 0 1px;
  }
}
</style>
