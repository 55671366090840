<template>
  <div class="steps">
    <AppButton v-if="withPrev" @click="prevAction"> Previous </AppButton>
    <AppButton
      @click="nextAction"
      id="next-btn"
      :type="type"
      align="right"
      :class="{ btnDdisabled: disabledNextBtn }"
    >
      Next
    </AppButton>
  </div>
</template>

<script>
import AppButton from "@/components/common/AppButton";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();

export default {
  name: "ScheduleStepsAction",
  components: { AppButton },
  props: {
    withPrev: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "button",
    },
    isNextStepRequired: {
      type: Boolean,
      default: true,
    },
    validated: {
      type: Boolean,
      default: true,
    },
    disabledNextBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    nextAction() {
      this.$emit("next");
      if (this.isNextStepRequired && this.validated === true)
        stepsStore.nextStep();
    },
    prevAction() {
      this.$emit("prev");
      stepsStore.prevStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  & > :first-child {
    margin-right: 8px;
  }
  & > :nth-child(2) {
    margin-left: 8px;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    & > :first-child {
      margin-bottom: 16px;
      margin-right: 0;
    }
    & > :last-child {
      margin-left: 0;
    }
  }
  & button {
    max-width: 398px;
    width: 100%;
  }
}

.btnDdisabled {
  background-color: #ccc;
  pointer-events: none;
}
</style>
