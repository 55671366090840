export const authSettings = {
  COOKIE_TOKEN_NAME: "SV_AUTHTOKEN",
  AUTH_HEADER: "SAVKATAUTHKEY",
};

export const requestFormSteps = Object.freeze({
  ScheduleStepsWelcome: {
    key: 1,
    title: "Welcome",
  },
  ScheduleStepsAccount: {
    key: 2,
    title: "Create your account",
  },
  // ScheduleStepsTiming: {
  //   key: 3,
  //   title: "Timing",
  // },
  ScheduleStepsPersonalInfo: {
    key: 3,
    title: "Contact Information",
  },
  ScheduleStepsTerms: {
    key: 4,
    title: "Accept Terms",
  },
  SchedulePhotoUpload: {
    key: 5,
    title: "Photo Upload",
  },
  ScheduleStepsRentOrOwn: {
    key: 6,
    title: "Rent or Own",
  },
  ScheduleStepsPropertyType: {
    key: 7,
    title: "Property Type",
  },
  ScheduleStepsChargerLocation: {
    key: 8,
    title: "Charger Location",
  },
  ScheduleStepsGarageSize: {
    key: 9,
    title: "Size of Garage",
  },
  ScheduleStepsWallMount: {
    key: 10,
    title: "Wall Mount",
  },
  ScheduleStepsElectricalPanel: {
    key: 11,
    title: "Electrical Panel",
  },
  ScheduleStepsElectricalPanelLocation: {
    key: 12,
    title: "Electrical Panel location",
  },
  ScheduleStepsElectricalPanelBreaker: {
    key: 13,
    title: "Electrical Panel Breaker",
  },
  ScheduleStepsEVChargerDistance: {
    key: 14,
    title: "EV Charger Distance",
  },
  // ScheduleStepsWiringAccess: {
  //   key: 15,
  //   title: "Wiring Access",
  // },
  ScheduleStepsAgeOfHome: {
    key: 15,
    title: "Home Information",
  },
  // ScheduleStepsHomeSize: {
  //   key: 17,
  //   title: "Size of Home",
  // },
  // ScheduleStepsCheckList: {
  //   key: 16,
  //   title: "Check list",
  // },
  ScheduleStepsSolarPower: {
    key: 16,
    title: "Solar Power",
  },
  ScheduleStepsScheduleInstallation: {
    key: 17,
    title: "Schedule Your Installation",
  },
  ScheduleStepsCongrats: {
    key: 18,
  },
});
