<template>
  <fieldset :id="id" class="wrapper">
    <label
      v-for="(option, index) in options"
      :key="option.text"
      class="form-control"
    >
      <input
        :id="id + index"
        :name="id"
        type="radio"
        :value="option.value"
        :checked="option.value === modelValue"
        :disabled="disabled"
        :required="required"
        @change="updateValue"
      />
      <label :for="id + index">{{ option.text }}</label>
      <slot v-if="option.value === modelValue" :name="option.value" />
    </label>
    <div class="progressbar" :id="id">
      <span
        class="progressbar__line"
        :style="{ width: progressCompleted + '%' }"
      ></span>
    </div>
  </fieldset>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["update", "update:modelValue"]);

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>

<script>
export default {
  name: "AppRadioSlider",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    progressCompleted() {
      const index = this.options.findIndex(
        (item) => item.value === this.modelValue
      );
      if (index > 0) {
        return (this.options.length - 1) * index;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  position: relative;
  max-width: 80%;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.form-control {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 15;
  &:nth-child(1) {
    align-items: flex-start;
  }
  &:nth-child(11) {
    align-items: flex-end;
  }
  @media screen and (max-width: 1024px) {
    &:nth-child(1) {
      align-items: center;
    }
    &:nth-child(11) {
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 36px;
    &:nth-child(1) {
      align-items: flex-start;
    }
    &:nth-child(11) {
      align-items: flex-start;
    }
  }
}
input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0 0 24px 0;
  font: inherit;
  width: 40px;
  height: 40px;
  border: 1px solid #707070;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  color: #fff;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}
input[type="radio"]::before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  @media screen and (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
}
input[type="radio"]:checked::before {
  transform: scale(1.15);
  background: var(--buzz-charging-dark-blue);
}
label {
  color: #888888;
  font: 18px Montserrat;
  label {
    @media screen and (max-width: 1200px) {
      transform: rotate(45deg) translateX(10px) translateY(-10px);
    }
    @media screen and (max-width: 768px) {
      margin-left: 16px;
      transform: rotate(0) translateX(0) translateY(50%);
    }
  }
  @media screen and (max-width: 1400px) {
    font-size: 16px;
  }
  // @media screen and (max-width: 768px) {
  //   transform: rotate(35deg) translateX(10px);
  // }
  // @media screen and (max-width: 670px) {
  //   margin-left: 16px;
  //   transform: rotate(0) translateX(0) translateY(50%);
  // }
}
.progressbar {
  height: 20px;
  position: absolute;
  width: 100%;
  top: 20px;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #dddddd;
  border-radius: 20px;
  @media screen and(max-width: 768px) {
    display: none;
  }
  &__line {
    display: block;
    height: 100%;
    background: var(--buzz-charging-yellow);
    overflow: hidden;
    border-radius: 20px;
    z-index: 10;
    @media screen and(max-width: 670px) {
      display: none;
    }
  }
}
</style>
