<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal-main"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <Calendar
          :value="modelValue"
          @dayclick="updateData"
          :min-date="nextAvailableDate"
          :disabled-dates="disabledDates"
          :available-dates="availableDates"
          mode="datetime"
          locale="en"
          :attributes="attributes"
          :from-page="{ month: choosenMonth, year: choosenYear }"
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AppModalCalendar",
  props: {
    modelValue: {
      type: String,
    },
    nextAvailableDate: {
      type: Date,
      default: new Date(),
    },
    disabledDates: {
      type: Object,
    },
    availableDates: {
      type: Object,
    },
  },
  computed: {
    choosenDate() {
      return this.modelValue;
    },
    attributes() {
      return [
        {
          highlight: true,
          dates: this.choosenDate,
        },
      ];
    },
    choosenMonth() {
      if (this.modelValue) {
        return +this.modelValue.split("/")[0];
      }
      return "";
    },
    choosenYear() {
      if (this.modelValue) {
        return this.modelValue.split("/")[2];
      }
      return "";
    },
  },
  methods: {
    updateData(val) {
      // console.log(val);
      let date = new Date(val.ariaLabel).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      this.$emit("update", date);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-main {
  overflow-x: auto;
  opacity: 1;
  flex-direction: column;
  border-radius: 15px;
  max-width: 608px;
  width: 100%;
  height: 100%;
  max-height: 536px;
  margin: 0 auto;
  @media screen and (max-width: 550px) {
    // margin: 0 16px;
  }
}
</style>
