<template>
  <div
    class="TextInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <fieldset>
      <label class="TextInput__label" :for="name" v-if="label">
        {{ label }}<span v-if="required" style="color: red">*</span>
      </label>
      <p class="TextInput__sub-label" v-if="subLabel">{{ subLabel }}</p>
      <input
        class="TextInput__input"
        :name="name"
        :id="name"
        :type="type"
        :value="inputValue"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        @input="handleChange"
        @blur="handleBlur"
      />
    </fieldset>

    <p
      class="help-message"
      v-show="errorMessage || meta.valid"
      :class="{ 'white-error': checkPageName }"
    >
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { computed, toRef } from "vue";
import { useRoute } from "vue-router";
// import { autofill } from "@mapbox/search-js-web";

export default {
  name: "AppInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    subLabel: {
      type: String,
      default: "",
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const name = toRef(props, "name");
    const route = useRoute();
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      initialValue: props.value,
    });
    const checkPageName = computed(() => {
      return route.name === "Dashboard";
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      checkPageName,
    };
  },
  methods: {
    onChange() {
      this.$emit("change");
    },
    async onBlur(e, validate) {
      this.focused = false;
      this.$emit("blur");
      await validate(this.value);
    },
    onInput($event) {
      this.$emit("input", $event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.TextInput {
  position: relative;
  display: inline-block;
  margin-bottom: calc(1em * 2);
  width: 100%;

  &__label {
    display: block;
    margin-bottom: 12px;
    width: 100%;
    color: #888888;
    font: normal normal 400 20px/24px Montserrat;
    letter-spacing: 1px;
  }
  &__sub-label {
    display: block;
    margin-bottom: 18px;
    width: 100%;
    color: #888888;
    font: italic normal medium 20px/30px Montserrat;
  }

  &__input {
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    padding: 15px 20px;
    outline: none;
    width: 100%;
    background-color: #f2f4f8;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &::placeholder {
      color: var(--placeholder-color);
    }
  }
}

.help-message {
  position: absolute;
  left: 0;
  margin: 5px 0 0 0;
  font-size: 12px;
}

.TextPasswordInput.has-error {
  margin-bottom: calc(1.5em * 1.5);
}
.TextInput.has-error input {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextInput.has-error input:focus {
  border-color: var(--error-color);
}

.TextInput.has-error .help-message {
  color: var(--error-color);
}
.white-error {
  color: #fff !important;
}
</style>
