<template>
  <div class="account">
    <h2 class="account__subtitle">
      Please create your personal login information
    </h2>
    <Form
      class="account__form"
      @submit="onSubmit"
      :validation-schema="signInSchema"
      @invalid-submit="onInvalidSubmit"
    >
      <AppInput
        name="email"
        placeholder="Email "
        label="Email"
        type="email"
        required
      />
      <AppPasswordInput
        withIcon
        name="password"
        placeholder="Password"
        label="Password"
        type="password"
        required
      />
      <AppPasswordInput
        withIcon
        name="confirmPassword"
        placeholder="Confirm Password"
        label="Confirm Password"
        type="password"
        required
      >
      </AppPasswordInput>
      <router-link class="loginLink" to="/">Already a customer?</router-link>
      <!--      <AppRadioGroup-->
      <!--        id="customers"-->
      <!--        v-model="selectedCustomerType"-->
      <!--        :options="customers"-->
      <!--        label="Customer Type:"-->
      <!--      />-->
      <div class="actionButtons">
        <AppButton align="left" @click="prevAction"> Previous </AppButton>
        <AppButton align="right" class="submit-btn" type="submit"
          >Next</AppButton
        >
      </div>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import AppInput from "@/components/common/AppInput";
import AppPasswordInput from "@/components/common/AppPasswordInput";
import AppButton from "@/components/common/AppButton";
import * as Yup from "yup";
import { useAuthStore } from "@/store/AuthStore";
import { ref } from "vue";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const authStore = useAuthStore();
const scheduleStore = useScheduleStore();

export default {
  name: "ScheduleStepsAccount",
  components: { AppButton, AppInput, Form, AppPasswordInput },
  setup() {
    const selectedCustomerType = ref("");
    const customers = [
      {
        value: "residential",
        text: "Residential Customer",
      },
      {
        value: "commercial",
        text: "Commercial Customer ",
      },
    ];

    const signInSchema = Yup.object().shape({
      email: Yup.string().email().required("Email is a required field"),
      password: Yup.string()
        .min(8)
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Password must contain at least 8 Characters, at least one Uppercase and Lowercase, and one Number"
        ),
      confirmPassword: Yup.string()
        .required("Confirm password is a required field")
        .oneOf([Yup.ref("password")], "Passwords do not match"),
    });
    function onInvalidSubmit() {
      const submitBtn = document.querySelector(".app-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    }

    function onSubmit(values) {
      const data = {
        email: values.email,
        password: values.password,
        customer_type: selectedCustomerType.value,
      };
      authStore.registerUser(data);
    }

    function prevAction() {
      scheduleStore.prevStep();
    }

    return {
      customers,
      selectedCustomerType,
      signInSchema,
      onInvalidSubmit,
      onSubmit,
      prevAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.loginLink {
  margin-bottom: 10px;
}
.TextInput,
.TextPasswordInput {
  width: 50%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.app-btn {
  max-width: 395px;
  margin-top: 50px;
  @media screen and (max-width: 1024px) {
    margin-right: -30px;
  }
}
.account {
  margin-top: 0px;
  @media screen and (max-width: 1024px) {
    margin-right: 60px;
  }
  @media screen and (max-width: 500px) {
    margin-right: 40px;
  }
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal 400 20px/30px Montserrat;
    margin-bottom: 50px;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
}
.actionButtons {
  display: flex;
  width: 80%;
  button {
    margin-right: 0;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
}
</style>
