<template>
  <div class="size">
    <h2 class="size__subtitle">What is the size of your home? (est.)</h2>
    <div class="size__body">
      <AppNumberInput
        class="size__input"
        label="Square Feet"
        placeholder="0"
        name="size"
        id="size"
        v-model="squareFeet"
      />
      <AppRadioGroup id="dont-know" :options="options" v-model="selected" />
      <div class="helpMessageBlock">
        <p
          class="helpMessage"
          :class="{ helpMessage__active: validated === false }"
        >
          This field is required
        </p>
      </div>
    </div>
    <!--    <ScheduleStepsAction @next="updateData" :validated="validated" />-->
  </div>
</template>

<script>
import AppNumberInput from "@/components/common/AppNumberInput";
import AppRadioGroup from "@/components/common/AppRadioGroup";
import { ref, reactive, watch } from "vue";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsHomeSize",
  components: { AppRadioGroup, AppNumberInput },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const squareFeet =
      localStepData?.home_size !== undefined &&
      localStepData?.home_size !== "Don't know"
        ? ref(localStepData?.home_size)
        : ref("");
    const selected =
      localStepData?.home_size === null ? ref("Don't know") : ref("");
    const options = [
      {
        value: "Don't know",
        text: "Don't know",
      },
    ];

    let validated =
      squareFeet.value === "" && selected.value === "" ? ref(null) : ref(true);

    watch(squareFeet, () => {
      if (squareFeet.value !== "") {
        selected.value = "";
        validated.value = squareFeet.value !== "";
      }
    });
    watch(selected, () => {
      if (selected.value !== "") {
        squareFeet.value = "";
        validated.value = selected.value !== "";
      }
    });

    function updateData() {
      if (selected.value === "" && squareFeet.value === "") {
        validated.value = false;
      }
      stepsStore.setScheduleStepData({
        home_size:
          squareFeet?.value && squareFeet?.value !== ""
            ? squareFeet?.value
            : selected.value === "Don't know"
            ? null
            : selected.value,
      });
    }

    return {
      selected,
      options,
      squareFeet,
      updateData,
      validated,
    };
  },
};
</script>

<style lang="scss" scoped>
.size {
  width: 80%;
  @media screen and (max-width: 1200px) {
    width: inherit;
  }

  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
    margin-bottom: 20px;
  }
  &__input {
    margin-bottom: 56px;
    // min-width: 100%;
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
