<template>
  <div class="installation">
    <div class="installation__datePickersBox">
      <AppDatePicker
        name="first"
        label="Enter First Choice"
        v-model="firstDate"
        placeholderByDefault="mm/dd/yy"
        :nextAvailableDate="nextAvailableDate"
        :disabledDates="disabledDates"
        :available-dates="availableDates"
      />
      <AppDatePicker
        name="second"
        label="Enter Second Choice"
        v-model="secondDate"
        placeholderByDefault="mm/dd/yy"
        :nextAvailableDate="nextAvailableDate"
        :disabledDates="disabledDates"
      />
    </div>

    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        Please choose 2 dates
      </p>
    </div>
    <div class="installation__text">
      <p>
        Please note: We will email you to confirm the installation, discuss any
        modifications and refine the time to a 2-hour window. Please ensure that
        someone is home aged 18+ on the day of the installation.
      </p>
      <p>
        Your installation date must be at least 2 weeks from the date you
        complete this form. However, please call us at 860-506-1500 to find out
        if earlier accommodations can be made.
      </p>
    </div>
    <ScheduleStepsAction
      @next="updateData"
      :isNextStepRequired="false"
      :validated="validated"
    />
  </div>
</template>

<script>
import AppDatePicker from "@/components/common/AppDatePicker";
import { ref, reactive, watch, computed } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsScheduleInstallation",
  components: { ScheduleStepsAction, AppDatePicker },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const firstDate = localStepData?.first_date_choice
      ? ref(localStepData?.first_date_choice)
      : ref("");
    const secondDate = localStepData?.second_date_choice
      ? ref(localStepData?.second_date_choice)
      : ref("");

    const nextAvailableDate = computed(() => {
      const numWeeks = 2;
      const date = new Date();
      date.setDate(date.getDate() + numWeeks * 7);
      return date;
    });

    const disabledDates = computed(() => {
      return { weekdays: [1, 7] };
    });

    const availableDates = computed(() => {
      return [firstDate.value, secondDate.value];
    });

    let validated = ref(true);

    watch(firstDate, () => {
      if (validated.value === false) {
        validated.value =
          firstDate.value !== "" && secondDate.value !== ""
            ? ref(true)
            : ref(null);
      }
    });
    watch(secondDate, () => {
      if (validated.value === false) {
        validated.value =
          firstDate.value !== "" && secondDate.value !== ""
            ? ref(true)
            : ref(null);
      }
    });

    async function updateData() {
      await stepsStore.setScheduleStepData({
        first_date_choice: firstDate.value,
        second_date_choice: secondDate.value,
      });
      if (firstDate.value === "" || secondDate.value === "") {
        validated.value = false;
        return;
      }
      makeOrderRequest();
    }
    async function makeOrderRequest() {
      console.log("values: ", stepsStore.scheduleData);
      localStorage.setItem(
        "typeOfProperty",
        stepsStore.scheduleData.property_status
      );
      await stepsStore.makeOrderRequest(stepsStore.scheduleData);
    }
    return {
      firstDate,
      secondDate,
      updateData,
      validated,
      nextAvailableDate,
      disabledDates,
      availableDates,
    };
  },
};
</script>

<style lang="scss" scoped>
.installation {
  width: 80%;
  color: var(--base-text-color);
  @media screen and(max-width: 550px) {
    width: inherit;
  }
  &__datePickersBox {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font: normal normal 20px/35px Montserrat;
    width: inherit;
    & > p:first-child {
      margin-bottom: 36px;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
