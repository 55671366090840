<template>
  <div class="location">
    <h2 class="location__subtitle">
      Where is your main electrical panel location?
    </h2>
    <div class="location__body">
      <AppRadioGroupWithImage
        id="panel-location"
        :options="options"
        v-model="selected"
      />
      <div class="helpMessageBlock">
        <p
          class="helpMessage"
          :class="{ helpMessage__active: validated === false }"
        >
          This field is required
        </p>
      </div>
    </div>
    <ScheduleStepsAction
      class="location__actions"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import AppRadioGroupWithImage from "@/components/common/AppRadioGroupWithImage";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsElectricalPanelLocation",
  components: { ScheduleStepsAction, AppRadioGroupWithImage },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected = localStepData?.electrical_panel_location
      ? ref(localStepData?.electrical_panel_location)
      : ref("");
    const options = [
      {
        value: "garage_wall",
        text: "Garage wall (inside or outside)",
        image: "/images/panel-location/GarageWall.jpg",
      },
      {
        value: "outside_home_wall",
        text: "Exterior wall of home (not on a garage wall)",
        image: "/images/panel-location/Exterior.jpg",
      },
      {
        value: "bare_basement",
        text: "Basement",
        image: "/images/panel-location/Basement.jpg",
      },
      {
        value: "finished_basement",
        text: "Finished Basement",
        image: "/images/panel-location/FinishedBasement.jpg",
      },
      {
        value: "other",
        text: "Other",
        image: "/images/Other.jpg",
      },
    ];
    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, () => {
      validated.value = selected.value !== "";
    });

    function updateData() {
      if (selected.value === "") {
        validated.value = false;
      }
      stepsStore.setScheduleStepData({
        electrical_panel_location: selected.value,
      });
    }

    return {
      selected,
      options,
      updateData,
      validated,
    };
  },
};
</script>

<style lang="scss" scoped>
.location {
  &__subtitle {
    color: var(--base-text-color);
    font: normal normal bold 30px/68px Montserrat;
    @media screen and (max-width: 550px) {
      font: normal normal bold 20px/38px Montserrat;
    }
  }
  &__body {
    margin-top: 26px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
