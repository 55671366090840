<template>
  <div class="schedule-header">
    <h2 class="schedule-header__title page-title">
      SCHEDULE YOUR INSTALLATION
    </h2>
    <AppProgressBar :progress="scheduleProgress" />
  </div>
</template>

<script>
import AppProgressBar from "@/components/common/AppProgressBar";
import { requestFormSteps } from "@/common/enums";
export default {
  name: "ScheduleHeader",
  components: { AppProgressBar },
  data() {
    return {
      countableSteps: Object.keys(requestFormSteps).length - 1,
    };
  },
  props: {
    activeTab: { type: Number, default: 1 },
  },
  computed: {
    scheduleProgress() {
      if (this.activeTab === 1) {
        return (100 / this.countableSteps) * (this.activeTab - 1) + 1;
      } else if (this.activeTab === this.countableSteps) {
        return 100 - 1;
      } else {
        return (100 / this.countableSteps) * (this.activeTab - 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-header {
  &__title {
    font: normal normal bold 40px/68px Futura;
    letter-spacing: 0px;
    margin-bottom: 40px;
    @media (max-width: 500px) {
      font-size: 40px;
      line-height: 1.4;
    }
  }
}
</style>
