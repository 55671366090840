<template>
  <div class="property-type">
    <div class="property-type__subtitle">
      <h2 class="property-type__subtitle-title">
        At what type of property will the charging station be installed?
      </h2>
    </div>
    <AppRadioGroupWithImage
      class="property-type__body"
      v-model="selected"
      :options="options"
      id="property"
      perRow="3"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        This field is required
      </p>
    </div>
    <ScheduleStepsAction
      class="property-type__actions"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import AppRadioGroupWithImage from "@/components/common/AppRadioGroupWithImage";
import { reactive, ref, watch } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsPropertyType",
  components: { ScheduleStepsAction, AppRadioGroupWithImage },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selected = localStepData?.property_type
      ? ref(localStepData?.property_type)
      : ref("");
    const options = [
      {
        value: "single_family_home",
        text: "Single Family Home",
        image: "/images/property-type/Single.jpg",
      },
      {
        value: "condo",
        text: "Condo",
        image: "/images/property-type/Condo.jpg",
      },
      {
        value: "townhouse",
        text: "Townhouse",
        image: "/images/property-type/Condo.jpg",
      },
      {
        value: "duplex",
        text: "Duplex",
        image: "/images/property-type/Duplex.jpg",
      },
      {
        value: "apartment",
        text: "Apartment",
        image: "/images/property-type/Apartment.jpg",
      },
      {
        value: "mobile_home",
        text: "Mobile Home",
        image: "/images/property-type/MobileHome.jpg",
      },
    ];
    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, () => {
      validated.value = selected.value !== "";
    });

    function updateData() {
      // console.log(selected);
      if (selected.value === "") {
        validated.value = false;
      }
      stepsStore.setScheduleStepData({
        property_type: selected.value,
      });
    }

    return {
      selected,
      options,
      updateData,
      localStepData,
      validated,
    };
  },
};
</script>

<style lang="scss" scoped>
.property-type {
  &__subtitle {
    color: var(--base-text-color);

    &-title {
      font: normal normal bold 30px/68px Montserrat;
      @media screen and (max-width: 550px) {
        font: normal normal bold 20px/38px Montserrat;
      }
    }
  }
  &__body {
    margin-top: 26px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
