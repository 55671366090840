<template>
  <div class="photoUpload">
    <!-- <h2 class="photoUpload__subtitle">Upload photo</h2> -->
    <div class="photoUpload__loading" v-if="loading">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <iframe
      :class="{ 'd-none': loading }"
      class="photoUpload__iframe"
      ref="photoIframe"
      :src="iframeUrl"
      frameborder="0"
      width="100%"
      @load="iframeIsLoaded"
    ></iframe>
    <ScheduleStepsAction
      class="photoUpload__actions"
      @next="updateData"
      :disabledNextBtn="!validated"
      :validated="validated"
    />
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";

import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();

export default {
  name: "SchedulePhotoUpload",
  components: { ScheduleStepsAction },

  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const loading = ref(true);
    const validated = localStepData?.image_uploaded ? ref(true) : ref(false);
    const photoIframe = ref(null);
    const baseUrl =
      "https://8567842.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=1818&deploy=1&compid=8567842&h=161cbbbfe62deca31bbe";
    const confirmationId = stepsStore.scheduleData.confirmation;
    const iframeUrl = baseUrl + `&confid=${confirmationId}`;
    function listenMessage(msg) {
      if (msg.data === "success") {
        validated.value = true;
      }
    }
    if (window.addEventListener) {
      window.addEventListener("message", listenMessage, false);
    } else {
      window.attachEvent("onmessage", listenMessage);
    }
    const iframeIsLoaded = () => {
      loading.value = false;
    };
    function updateData() {
      if (validated.value === true) {
        stepsStore.setScheduleStepData({
          image_uploaded: true,
        });
      }
    }
    return {
      loading,
      validated,
      updateData,
      iframeIsLoaded,
      photoIframe,
      iframeUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.d-none {
  display: none;
}
.photoUpload__iframe {
  height: 80vh;
}
.photoUpload__loading {
  text-align: center;
  padding: 50px 0;
}
.photoUpload__actions {
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
// loader
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--buzz-charging-yellow);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
