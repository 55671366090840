<template>
  <div class="charger">
    <div class="charger__subtitle">
      <h2 class="charger__subtitle-title">
        Where would you like your charging location installed?
      </h2>
    </div>
    <AppRadioGroupWithImage
      fullWidth
      class="charger__body"
      v-model="selected"
      :options="options"
      id="property"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: showRequiredMessage }"
      >
        This field is required
      </p>
    </div>
    <div class="charger__text">
      <p>Recommended on Inside/Outside Wall of Garage/Carport</p>
    </div>
    <ScheduleStepsAction
      class="charger__actions"
      @next="updateData"
      :validated="validated"
    />
    <AdditionalCostsModal
      v-if="showModal"
      @close="showModal = false"
      @agreed="goToNextStep"
      customText="To mount to a standalone pedestal"
    />
  </div>
</template>

<script>
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import AppRadioGroupWithImage from "@/components/common/AppRadioGroupWithImage";
import AdditionalCostsModal from "@/components/layouts/AdditionalCostsModal";
import { ref, reactive, watch, computed } from "vue";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsChargerLocation",
  components: {
    AppRadioGroupWithImage,
    ScheduleStepsAction,
    AdditionalCostsModal,
  },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    let showModal = ref(false);
    const selected = localStepData?.charger_location
      ? ref(localStepData?.charger_location)
      : ref("");
    const options = [
      {
        value: "garage_wall",
        text: "Inside/outside wall of my garage or carport",
        image: "/images/charger-location/Inside.jpg",
      },
      {
        value: "outside_home_wall",
        text: "On the outside wall of my home near my parking space",
        image: "/images/charger-location/Outside.jpg",
      },
      // {
      //   value: "mounted_to_fence",
      //   text: "Mounted on fence",
      //   image: "/images/charger-location/Fence.jpg",
      // },
      {
        value: "mounted_to_pedestal",
        text: "Mounted to standalone pedestal",
        image: "/images/charger-location/Pedestal.jpg",
      },
    ];
    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, () => {
      validated.value =
        selected.value !== "" && selected.value !== "mounted_to_pedestal";
    });

    const showRequiredMessage = computed(() => {
      return (
        validated.value === false && selected.value !== "mounted_to_pedestal"
      );
    });

    const goToNextStep = () => {
      validated.value = true;
      showModal.value = false;
      stepsStore.nextStep();
      stepsStore.nextStep();
      stepsStore.nextStep();
    };

    function updateData() {
      if (selected.value === "") {
        validated.value = false;
      }
      if (
        validated.value === true ||
        selected.value === "mounted_to_pedestal"
      ) {
        if (
          selected.value === "mounted_to_fence" ||
          selected.value === "outside_home_wall"
        ) {
          stepsStore.nextStep();
        }
        if (selected.value === "mounted_to_pedestal") {
          if (validated.value === true) {
            stepsStore.nextStep();
            stepsStore.nextStep();
          } else {
            showModal.value = true;
            validated.value = false;
          }
        }
      }

      if (stepsStore?.scheduleData?.charger_location !== selected.value) {
        stepsStore.removeProperty("garage_size");
        stepsStore.removeProperty("wall_mount");
        stepsStore.removeProperty("outside_charger_location");
        stepsStore.removeProperty("fence_charger_location");
      }

      stepsStore.setScheduleStepData({
        charger_location: selected.value,
      });
    }
    return {
      selected,
      options,
      updateData,
      validated,
      showModal,
      goToNextStep,
      showRequiredMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.charger {
  &__subtitle {
    color: var(--base-text-color);

    &-title {
      font: normal normal bold 30px/68px Montserrat;
      @media screen and (max-width: 550px) {
        font: normal normal bold 20px/38px Montserrat;
      }
    }
  }
  &__body {
    margin-top: 26px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  &__text {
    font: normal normal 20px/35px Montserrat;
    width: inherit;
    color: var(--base-text-color);
    p {
      margin-bottom: 36px;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
