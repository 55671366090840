<template>
  <div class="solar">
    <AppRadioGroup
      id="solar-panels"
      v-model="powerBySolar"
      :options="powerBySolarOptions"
      label="Is your home powered by solar panels?"
    />
    <AppRadioGroup
      v-if="powerBySolar === 'yes'"
      id="solar-storage"
      v-model="solarStorage"
      :options="solarStorageOptions"
      label="Do you have solar storage?"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        Please answer all questions
      </p>
    </div>
    <ScheduleStepsAction
      class="solar-panel__actions"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import AppRadioGroup from "@/components/common/AppRadioGroup";
import { ref, reactive, watch } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepsSolarPower",
  components: { ScheduleStepsAction, AppRadioGroup },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const powerBySolar =
      localStepData?.powered_by_solar_panels === true
        ? ref("yes")
        : localStepData?.powered_by_solar_panels === false
        ? ref("no")
        : ref("");
    const powerBySolarOptions = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];
    const solarStorage =
      localStepData?.solar_storage === true
        ? ref("yes")
        : localStepData?.solar_storage === false
        ? ref("no")
        : ref("");
    const solarStorageOptions = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];

    const validated =
      (powerBySolar.value === "yes" && solarStorage.value !== "") ||
      powerBySolar.value === "no"
        ? ref(true)
        : ref(null);

    watch(powerBySolar, (val) => {
      if (val === "") {
        validated.value = null;
      } else if (val === "yes" && solarStorage.value === "") {
        validated.value = null;
      } else {
        validated.value = true;
      }
    });
    watch(solarStorage, () => {
      validated.value =
        solarStorage.value !== "" && powerBySolar.value !== "" ? true : null;
    });

    function updateData() {
      if (
        (powerBySolar.value === "" && solarStorage.value === "") ||
        (powerBySolar.value === "yes" && solarStorage.value === "")
      ) {
        validated.value = false;
      }
      if (validated.value === true) {
        if (powerBySolar.value === "yes") {
          stepsStore.setScheduleStepData({
            powered_by_solar_panels: true,
            solar_storage: solarStorage.value === "yes",
          });
        } else if (powerBySolar.value === "no") {
          stepsStore.setScheduleStepData({
            powered_by_solar_panels: false,
            solar_storage: null,
          });
        } else {
          stepsStore.setScheduleStepData({
            powered_by_solar_panels: powerBySolar.value === "yes",
            solar_storage: solarStorage.value === "yes",
          });
        }
      }
    }

    return {
      powerBySolar,
      powerBySolarOptions,
      solarStorage,
      solarStorageOptions,
      updateData,
      validated,
    };
  },
};
</script>

<style lang="scss" scoped>
.solar-panel {
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
