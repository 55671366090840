<template>
  <div class="check-list">
    <AppRadioGroup
      id="central-air"
      v-model="selectedAir"
      :options="centralAir"
      label="Do you have Central Air?"
    />
    <AppRadioGroup
      id="hot-tube"
      v-model="selectedTube"
      :options="hotTube"
      label="Do you have a Hot Tub?"
    />
    <AppRadioGroup
      id="pool-heater"
      v-model="selectedPoolHeater"
      :options="poolHeater"
      label="Do you have a Pool Heater?"
    />
    <AppRadioGroup
      id="refrigerators"
      v-model="selectedRefrigerator"
      :options="refrigerators"
      label="Do you have Multiple Refrigerators?"
    />
    <AppRadioGroup
      id="water-tank"
      v-model="selectedHotWaterTanks"
      :options="hotWaterTanks"
      label="Do you have an Electric Hot Water Tank?"
    />
    <AppRadioGroup
      id="electric-range"
      v-model="selectedElRange"
      :options="electricRange"
      label="Do you have an Electric Range?"
    />
    <AppRadioGroup
      id="el-furnace"
      v-model="selectedElFurnace"
      :options="electricFurnace"
      label="Do you have an Electric Furnace?"
    />
    <AppRadioGroup
      id="generator"
      v-model="selectedGenerator"
      :options="generator"
      label="Do you have a Generator?"
    />
    <AppRadioGroup
      id="wifi"
      v-model="selectedWiFi"
      :options="wifi"
      label="Is there a strong Wi-Fi signal where you want the charger installed?"
    />
    <div class="helpMessageBlock">
      <p
        class="helpMessage"
        :class="{ helpMessage__active: validated === false }"
      >
        Please answer all questions
      </p>
    </div>
    <ScheduleStepsAction
      class="check-list__actions"
      @next="updateData"
      :validated="validated"
    />
  </div>
</template>

<script>
import AppRadioGroup from "@/components/common/AppRadioGroup";
import { ref, reactive, watch } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();
export default {
  name: "ScheduleStepChecklist",
  components: { ScheduleStepsAction, AppRadioGroup },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const selectedAir =
      localStepData?.central_air !== undefined
        ? localStepData.central_air === true
          ? ref("yes")
          : localStepData.central_air === false
          ? ref("no")
          : ref("")
        : ref("");
    const centralAir = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];
    const selectedTube =
      localStepData?.hot_tub === true
        ? ref("yes")
        : localStepData?.hot_tub === false
        ? ref("no")
        : ref("");
    const hotTube = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];
    const selectedPoolHeater =
      localStepData?.pool_heater === true
        ? ref("yes")
        : localStepData?.pool_heater === false
        ? ref("no")
        : ref("");
    const poolHeater = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];
    const selectedRefrigerator =
      localStepData?.few_fridges === true
        ? ref("yes")
        : localStepData?.few_fridges === false
        ? ref("no")
        : ref("");
    const refrigerators = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];

    const selectedHotWaterTanks =
      localStepData?.hot_water_tank === true
        ? ref("yes")
        : localStepData?.hot_water_tank === false
        ? ref("no")
        : ref("");
    const hotWaterTanks = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];

    const selectedElRange =
      localStepData?.electric_range === true
        ? ref("yes")
        : localStepData?.electric_range === false
        ? ref("no")
        : ref("");
    const electricRange = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];
    const selectedElFurnace =
      localStepData?.electric_furnace === true
        ? ref("yes")
        : localStepData?.electric_furnace === false
        ? ref("no")
        : ref("");
    const electricFurnace = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];
    const selectedGenerator =
      localStepData?.generator === true
        ? ref("yes")
        : localStepData?.generator === false
        ? ref("no")
        : ref("");

    const generator = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
    ];
    const selectedWiFi =
      localStepData?.wifi === true
        ? ref("yes")
        : localStepData?.wifi === false
        ? ref("no")
        : localStepData?.wifi === "unknown"
        ? ref("unknown")
        : ref("");
    const wifi = [
      {
        value: "yes",
        text: "Yes",
      },
      {
        value: "no",
        text: "No",
      },
      {
        value: "unknown",
        text: "Don't know",
      },
    ];

    const checkUpdates = (collName, emptyValue = null) => {
      const values = [];
      // console.log(collName);
      for (const key of Object.keys(collName)) {
        values.push(collName[key]["_value"]);
      }
      if (values.indexOf("") > -1) {
        validated.value = emptyValue;
        return "";
      } else {
        validated.value = true;
        return true;
      }
    };
    const allQuestions = {
      selectedAir: selectedAir.value !== "" ? ref(selectedAir.value) : ref(""),
      selectedTube:
        selectedTube.value !== "" ? ref(selectedTube.value) : ref(""),
      selectedPoolHeater:
        selectedPoolHeater.value !== ""
          ? ref(selectedPoolHeater.value)
          : ref(""),
      selectedRefrigerator:
        selectedRefrigerator.value !== ""
          ? ref(selectedRefrigerator.value)
          : ref(""),
      selectedHotWaterTanks:
        selectedHotWaterTanks.value !== ""
          ? ref(selectedHotWaterTanks.value)
          : ref(""),
      selectedElRange:
        selectedElRange.value !== "" ? ref(selectedElRange.value) : ref(""),
      selectedElFurnace:
        selectedElFurnace.value !== "" ? ref(selectedElFurnace.value) : ref(""),
      selectedGenerator:
        selectedGenerator.value !== "" ? ref(selectedGenerator.value) : ref(""),
      selectedWiFi:
        selectedWiFi.value !== "" ? ref(selectedWiFi.value) : ref(""),
    };
    const isEmptyQuestion = (collName) => {
      const values = [];
      for (const key of Object.keys(collName)) {
        values.push(collName[key]["_value"]);
      }
      if (values.indexOf("") > -1) {
        return true;
      } else {
        return false;
      }
    };
    let validated = isEmptyQuestion(allQuestions) ? ref(null) : ref(true);

    watch(selectedAir, () => {
      allQuestions.selectedAir.value = selectedAir.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedTube, () => {
      allQuestions.selectedTube.value = selectedTube.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedPoolHeater, () => {
      allQuestions.selectedPoolHeater.value = selectedPoolHeater.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedRefrigerator, () => {
      allQuestions.selectedRefrigerator.value =
        selectedRefrigerator.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedHotWaterTanks, () => {
      allQuestions.selectedHotWaterTanks.value =
        selectedHotWaterTanks.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedElRange, () => {
      allQuestions.selectedElRange.value = selectedElRange.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedElFurnace, () => {
      allQuestions.selectedElFurnace.value = selectedElFurnace.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedGenerator, () => {
      allQuestions.selectedGenerator.value = selectedGenerator.value !== "";
      checkUpdates(allQuestions);
    });
    watch(selectedWiFi, () => {
      allQuestions.selectedWiFi.value = selectedWiFi.value !== "";
      checkUpdates(allQuestions);
    });

    function updateData() {
      checkUpdates(allQuestions, false);
      // console.log(allQuestions);
      if (validated.value === true) {
        stepsStore.setScheduleStepData({
          generator: selectedGenerator.value === "yes",
          electric_furnace: selectedElFurnace.value === "yes",
          electric_range: selectedElRange.value === "yes",
          hot_water_tank: selectedHotWaterTanks.value === "yes",
          few_fridges: selectedRefrigerator.value === "yes",
          pool_heater: selectedPoolHeater.value === "yes",
          hot_tub: selectedTube.value === "yes",
          central_air: selectedAir.value === "yes",
          wifi:
            selectedWiFi.value === "unknown"
              ? "unknown"
              : selectedWiFi.value === "yes",
        });
      }
    }

    return {
      selectedAir,
      centralAir,
      selectedTube,
      hotTube,
      selectedPoolHeater,
      poolHeater,
      selectedRefrigerator,
      refrigerators,
      selectedHotWaterTanks,
      hotWaterTanks,
      selectedElRange,
      electricRange,
      selectedElFurnace,
      electricFurnace,
      selectedGenerator,
      selectedWiFi,
      wifi,
      generator,
      updateData,
      validated,
      localStepData,
      allQuestions,
    };
  },
};
</script>

<style lang="scss" scoped>
.check-list {
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
