<template>
  <fieldset :id="id">
    <legend v-if="label">{{ label }}</legend>
    <label
      v-for="(option, index) in options"
      :key="option.text"
      class="form-control"
    >
      <input
        :id="id + index"
        :name="id"
        type="radio"
        :value="option.value"
        :checked="option.value === modelValue"
        :disabled="disabled"
        :required="required"
        @change="updateValue"
      />
      <label :for="id + index">{{ option.text }}</label>
      <slot v-if="option.value === modelValue" :name="option.value" />
    </label>
  </fieldset>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["update", "update:modelValue"]);

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>

<script>
export default {
  name: "AppRadioGroup",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    function updateValue(event) {
      if (props.modelValue === event.target.value) {
        ctx.emit("update:modelValue", null);
      }
      ctx.emit("update:modelValue", event.target.value);
    }

    return {
      updateValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0;
}
input[type="radio"] {
  position: relative;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border: 1px solid #707070;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  color: #fff;
}
input[type="radio"]::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--buzz-charging-dark-blue);
}
input[type="radio"]:checked::before {
  transform: translate(-50%, -50%) scale(1.15);
}
label {
  color: #888888;
  font: 18px Montserrat;
  padding-left: 16px;
}

legend {
  font: normal normal bold 30px/68px Montserrat;
  color: var(--base-text-color);
  margin-bottom: 7px;
  @media screen and (max-width: 550px) {
    font: normal normal bold 20px/38px Montserrat;
  }
}
</style>
