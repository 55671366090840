<template>
  <div class="rent">
    <div class="rent__subtitle">
      <h2 class="rent__subtitle-title">Do you rent or own the property?</h2>
    </div>
    <div class="rent__body">
      <AppRadioGroup :options="options" v-model="selected" id="rent" />
      <div class="helpMessageBlock">
        <p
          class="helpMessage"
          :class="{ helpMessage__active: validated === false }"
        >
          This field is required
        </p>
      </div>
    </div>
    <!-- <AppWarningModal
      v-if="showWarningModal"
      @close="showWarningModal = false"
    /> -->
    <ScheduleStepsAction
      class="rent__actions"
      @next="updateData"
      :validated="validated"
    />
    <RentalPropertyModal
      :rentType="selected"
      v-if="showPropertyModal"
      @close="showPropertyModal = false"
    />
  </div>
</template>

<script>
import RentalPropertyModal from "@/components/layouts/RentalPropertyModal";
import AppRadioGroup from "@/components/common/AppRadioGroup";
// import AppWarningModal from "../../layouts/AppWarningModal.vue";
import { ref, reactive, watch } from "vue";
import ScheduleStepsAction from "@/components/schedule/common/ScheduleStepsAction";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();

export default {
  name: "ScheduleStepsRentOrOwn",
  components: {
    ScheduleStepsAction,
    AppRadioGroup,
    // AppWarningModal,
    RentalPropertyModal,
  },
  setup() {
    const localStepData = reactive(stepsStore.scheduleData);
    const showPropertyModal = ref(false);
    // const showWarningModal = ref(false);
    const selected = localStepData?.property_status
      ? ref(localStepData?.property_status)
      : ref("");
    const options = [
      {
        value: "own",
        text: "Own",
      },
      {
        value: "rent",
        text: "Rent",
      },
    ];

    let validated = selected.value !== "" ? ref(true) : ref(null);

    watch(selected, () => {
      validated.value = selected.value !== "";
      if (selected.value === "rent") {
        validated.value = null;
      }
    });

    function updateData() {
      if (selected.value === "") {
        validated.value = false;
      }
      if (selected.value === "rent") {
        showPropertyModal.value = ref(true);
        return;
      }
      stepsStore.setScheduleStepData({
        property_status: selected.value,
      });
    }
    return {
      selected,
      options,
      updateData,
      validated,
      // showWarningModal,
      showPropertyModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.rent {
  &__subtitle {
    color: var(--base-text-color);

    &-title {
      font: normal normal bold 30px/68px Montserrat;
      @media screen and (max-width: 550px) {
        font: normal normal bold 20px/38px Montserrat;
      }
    }
  }
  &__body {
    margin-top: 52px;
  }
  &__actions {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.helpMessageBlock {
  height: 20px;
  .helpMessage {
    display: none;
    color: red;
    &__active {
      display: block;
    }
  }
}
</style>
